import classes from './brokerDashboard.module.scss';
import { actionBannerCampaignsApiCall, actionGetTopMenuButtonApiCall, actionGetMainMenuButtonApiCall, actionGetDashboardSliderApiCall, actionGetAgencyBannerRecommendationsApiCall } from '../../Redux/Actions';
import { useEffect, useState } from 'react';
import { useNavigate, Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '../../assets/icons/SearchIcon.svg'
import FacebookIcon from '../../assets/icons/bannerFacebookIcon.svg'
import InstagramIcon from '../../assets/icons/bannerInstagramIcon.svg'
import LinkedinIcon from '../../assets/icons/linkdinIcon.svg'
import ViewCountIcon from '../../assets/icons/viewIconBroker.svg'
import ClickIcon from '../../assets/icons/clickIconBroker.svg'
import GraphIcon from '../../assets/icons/graphIconBroker.svg';
import TvIcon from '../../assets/icons/TvIconBroker.svg'
import EditIcon from "../../assets/icons/EditIcon.svg"
import RocketIcon from "../../assets/icons/brokerRocketLargeIcon.svg"
import RocketIconSmall from "../../assets/icons/rocket_icon.svg"

const properties = [
    { id: 1, image: 'https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid', price: 250, size: 180, rooms: 25 },
    { id: 2, image: 'https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid', price: 250, size: 180, rooms: 25 },
    { id: 1, image: 'https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid', price: 250, size: 180, rooms: 25 },
    { id: 2, image: 'https://cdn-icons-png.freepik.com/256/5323/5323572.png?semt=ais_hybrid', price: 250, size: 180, rooms: 25 },
    // Add more properties as needed
];

const BrokerDashboardMain = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);

    const handleSwipe = (direction) => {
        let newIndex;
        if (direction === 'left' && currentIndex < (Main_Menu.length - 1)) {
            newIndex = currentIndex + 1;
        } else if (direction === 'right' && currentIndex > 0) {
            newIndex = currentIndex - 1;
        } else if (direction === 'left' && currentIndex === (Main_Menu.length - 1)) {
            newIndex = 0; // Loop to first slide
        } else if (direction === 'right' && currentIndex === 0) {
            newIndex = Main_Menu.length - 1; // Loop to last slide
        } else {
            return;
        }
        
        setCurrentIndex(newIndex);
        handleSetData(Main_Menu[newIndex]);
    };

    // Touch handlers
    const handleTouchStart = (e) => {
        setAutoPlay(false);
        setTouchStart(e.touches[0].clientX);
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        const swipeThreshold = 50;
        const diff = touchStart - touchEnd;

        if (Math.abs(diff) > swipeThreshold) {
            if (diff > 0) {
                handleSwipe('left');
            } else {
                handleSwipe('right');
            }
        }
        setAutoPlay(true);
    };

    // Mouse handlers
    const handleMouseDown = (e) => {
        setAutoPlay(false);
        setIsDragging(true);
        setStartX(e.pageX);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        const diff = startX - e.pageX;
        setTouchStart(startX);
        setTouchEnd(e.pageX);
    };

    const handleMouseUp = () => {
        if (!isDragging) return;
        setIsDragging(false);
        
        const swipeThreshold = 50;
        const diff = touchStart - touchEnd;

        if (Math.abs(diff) > swipeThreshold) {
            if (diff > 0) {
                handleSwipe('left');
            } else {
                handleSwipe('right');
            }
        }
        setAutoPlay(true);
    }
    const bannerCampaignsData = useSelector(
        (state) => state.CommonReducer.myBannerCampaignsData
    );
    const saveAgencyBannerRecommendations =
        useSelector(
            (state) => state.CommonReducer.saveAgencyBannerRecommendations
        ) || {};

    const Dashboard1 = saveAgencyBannerRecommendations?.['Dashboard 1'],
        Dashboard2 = saveAgencyBannerRecommendations?.['Dashboard 2'],
        Dashboard3 = saveAgencyBannerRecommendations?.['Dashboard 3'];


    console.log("Dashboard faddfare", Dashboard1, Dashboard2, Dashboard3);

    const Top_Menu = useSelector(
        (state) => state.CommonReducer.SaveTopMenuButton
    );
    const Main_Menu = useSelector(
        (state) => state.CommonReducer.SaveMainMenuButton
    )
    // const Dashboard_Slider = useSelector(
    //     (state) => state.CommonReducer.SaveSliderDashboardData
    // )

    const [sliderdata, setSliderdata] = useState({});

    // const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let body = { navigate };
        const data = {
            bannerSizes: [
                {
                    "Dashboard 1": "1595*346"
                },
                {
                    "Dashboard 2": "1595*346"
                },
                {
                    "Dashboard 3": "1595*346"
                }
            ],
        }
        dispatch(actionBannerCampaignsApiCall(body));
        dispatch(actionGetTopMenuButtonApiCall({ navigate }))
        dispatch(actionGetMainMenuButtonApiCall({ navigate }))
        // dispatch(actionGetDashboardSliderApiCall({ navigate }))
        dispatch(actionGetAgencyBannerRecommendationsApiCall({ data }))
    }, []);

    useEffect(() => {
        let slideInterval;
        
        if (autoPlay) {
            slideInterval = setInterval(() => {
                let newIndex;
                if (currentIndex < (Main_Menu?.length - 1)) {
                    newIndex = currentIndex + 1;
                } else {
                    newIndex = 0; // Reset to first slide
                }
                setCurrentIndex(newIndex);
                handleSetData(Main_Menu[newIndex]);
            }, 3000); // 1 second interval
        }
        
        return () => {
            if (slideInterval) {
                clearInterval(slideInterval);
            }
        };
    }, [currentIndex, autoPlay, Main_Menu]);

    const handlennavigate = (id) => {
        navigate(`/edit-banner/${id}`)
    }

    const handleSetData = (data) => {
        setSliderdata(data)
    }

    useEffect(()=>{
        setSliderdata(Main_Menu[0])
    },[])

    return (
        <main>
            <section className={classes.subheader}>
                <div className={classes.container}>
                    <div className={classes.buttons}>
                        {
                            Top_Menu && Top_Menu?.map((item) => (
                                <Link
                                    to={item.link}
                                    className={classes.button}
                                    style={{
                                        cursor: item.isActive ? 'pointer' : 'not-allowed',
                                        opacity: item.isActive ? 1 : 0.5,
                                    }}
                                >

                                    <img src={item.icon} width={18} height={16} />
                                    {item.name}
                                </Link>
                            ))
                        }

                        {/* <Link to="/creative-suite" className={classes.button}>
                            <i className="fa-regular fa-bullhorn"></i>
                            Creative suite
                        </Link>
                        <Link to="/manage-offer" className={classes.button}>
                            <i className="fa-regular fa-location-dot"></i>
                            Jouw aanbod beheren
                        </Link>
                        <Link to="/deleted" className={classes.button}>
                            <i className="fa-regular fa-trash"></i>
                            Verwijderd
                        </Link> */}
                    </div>
                    <div className={classes.search}>
                        <input type="text" placeholder="Zoeken..." />
                        <button className={classes.searchButton}>
                            <img src={SearchIcon} />
                        </button>
                    </div>
                </div>
            </section>

            <section className={classes.eyecandy}>
            <div
                className={classes.sliderWrapper}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                <ul 
                    className={classes.lightSlider}
                    style={{
                        transform: `translateX(-${currentIndex * 100}%)`,
                        transition: isDragging ? 'none' : 'transform 1500ms ease'
                    }}
                >
                    {Main_Menu && Main_Menu?.map((item, index) => (
                        <li key={index} style={{ width: '100%', flexShrink: 0 }}>
                            <div 
                                className={classes.image}
                                style={{
                                    backgroundImage: item.sliderImage ? `url(${item.sliderImage})` : 'none',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    height: '500px',
                                }}
                            >
                                <div className={classes.container}>
                                    <div className={classes.content}>
                                        <h2>{item.header}</h2>
                                        <p>{item.text}</p>
                                        <Link to={item.ctaButtonLink} className={classes.pinkbutton}>
                                            {item.ctaButtonText}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={classes.bigButtons}>
                {Main_Menu && Main_Menu?.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            setCurrentIndex(index);
                            handleSetData(item);
                        }}
                        className={`${classes.bigbutton} bigbutton ${currentIndex === index ? classes.active : ''}`}
                        style={{
                            cursor: item.isActive ? 'pointer' : 'not-allowed',
                            opacity: item.isActive ? 1 : 0.5,
                            gap: '12px',
                            textAlign: 'center',
                            textDecoration: "none",
                            display: "flex",
                            gap: "20px"
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#FF5FB3'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
                    >
                        <img src={item.icon} width={50} height="auto" alt={item.name} />
                        <span style={{
                            whiteSpace: 'normal',
                            lineHeight: '1.2',
                            textWrap: "wrap",
                            fontSize: "16.8px",
                            textAlign: "start",
                            wordBreak: "break-word",
                            width: "100%",
                            display: "block"
                        }}>
                            {item.name}
                        </span>
                    </div>
                ))}
            </div>
 </section>

            <div className={classes.campaignListing}>
                <div className={classes.sectionTitle}>
                    <h3>Woning campagnes</h3>
                    <div className={classes.actionButtons}>
                        <button className={classes.actionBtn}><img src={ViewCountIcon} /> Alle resultaten</button>
                        <button className={classes.actionBtn}
                        // onClick={()=>navigate("/add-banner")}
                        ><img src={RocketIconSmall} /> Start nieuwe campagne</button>
                    </div>
                </div>

                {/* <div className={classes.propertyGrid}> */}
                {/* <div className={classes.addCampaign}>
    <div className={classes.rocketIcon}>
      <img src={RocketIcon} />
    </div>
    <p>Nieuwe campagne toevoegen</p>
</div> */}

                <div className={classes.parentBlock}>
                    <div className={classes.addCampaign}>
                        <div className={classes.rocketicon}>
                        <img src={RocketIcon}
                        // onClick={()=>navigate("/add-banner")}
                          />
                        </div>
                        <p>nieuwe campagne toevoegen</p>
                    </div>
                    {bannerCampaignsData && bannerCampaignsData?.map(property => (
                        // <div key={property.id} className={classes.propertyCard}>
                        //     <div
                        //         className={classes.propertyImage}
                        //         style={{ backgroundImage: `url(${property.image})` }}
                        //     >
                        //         <div className={classes.propertyStats}>
                        //             <span className={classes.statBadge}>€ {property.price}k</span>
                        //             <span className={classes.statBadge}>{property.size}m²</span>
                        //             <span className={classes.statBadge}>{property.rooms}k</span>
                        //         </div>
                        //     </div>
                        //     <div className={classes.propertyFooter}>
                        //         <div className={classes.socialIcons}>
                        //             <button className={classes.iconBtn}>📧</button>
                        //             <button className={classes.iconBtn}>📱</button>
                        //             <button className={classes.iconBtn}>💼</button>
                        //             <button className={classes.iconBtn}>📎</button>
                        //         </div>
                        //         <button className={classes.viewBtn}>Bekijken</button>
                        //     </div>
                        // </div>
                        <div key={property.id} className={classes.propertyCard}>

                            <div className={classes.cardImage} style={{ backgroundImage: `url(${property.agency_detail.logo_image})` }}>
                                {
                                   property?.campaign_banners && property?.campaign_banners?.map((ele) => {
                                        return (
                                            <>
                                                <div className={classes.bigButtons}>
                                                    <Link
                                                        to={""}
                                                        className={classes.bigbutton}
                                                    // className={`${classes.bigbutton} bigbutton`}
                                                    // style={{
                                                    //     gap: '12px',
                                                    //     textAlign: 'center',
                                                    //     textDecoration: "none",
                                                    //     display: "flex",
                                                    //     gap: "30px"
                                                    // }}
                                                    >
                                                        {/* <img src={property.agency_detail.logo_image} /> */}
                                                        <div className={classes.infoContainer}>
                                                            <img src={ClickIcon} width={20} height={24} />
                                                            <span>
                                                                <span style={{
                                                                    whiteSpace: 'normal',
                                                                    lineHeight: '1',
                                                                    textWrap: "wrap",
                                                                    fontSize: "14px",
                                                                    textAlign: "start",

                                                                }}>
                                                                    {ele.property_banner.click_count || 0}
                                                                </span>
                                                                <div style={{
                                                                    fontSize: "14px"
                                                                }}>Click</div>
                                                            </span>
                                                        </div>

                                                    </Link>
                                                    <Link
                                                        to={""}
                                                        className={classes.bigbutton}
                                                    // className={`${classes.bigbutton} bigbutton`}
                                                    // style={{
                                                    //     gap: '12px',
                                                    //     textAlign: 'center',
                                                    //     textDecoration: "none",
                                                    //     display: "flex",
                                                    //     gap: "30px"
                                                    // }}
                                                    >
                                                        <div className={classes.infoContainer}>
                                                            <img src={ViewCountIcon} width={20} height={24} />
                                                            <span>
                                                                <span style={{
                                                                    whiteSpace: 'normal',
                                                                    lineHeight: '1',
                                                                    textWrap: "wrap",
                                                                    fontSize: "14px",
                                                                    textAlign: "start",

                                                                }}>
                                                                    {ele.property_banner.view_count || 0}
                                                                </span>
                                                                <div style={{
                                                                    fontSize: "14px"
                                                                }}>views</div>
                                                            </span>
                                                        </div>
                                                    </Link>
                                                    <Link
                                                        to={""}
                                                        className={classes.bigbutton}
                                                    // className={`${classes.bigbutton} bigbutton`}
                                                    // style={{
                                                    //     gap: '12px',
                                                    //     textAlign: 'center',
                                                    //     textDecoration: "none",
                                                    //     display: "flex",
                                                    //     gap: "30px"
                                                    // }}
                                                    >
                                                        <div className={classes.infoContainer}>
                                                            <img src={GraphIcon} width={20} height={24} />
                                                            <span>
                                                                <span style={{
                                                                    whiteSpace: 'normal',
                                                                    lineHeight: '1',
                                                                    textWrap: "wrap",
                                                                    fontSize: "14px",
                                                                    // textAlign: "start",

                                                                }}>
                                                                    25px
                                                                </span>
                                                                <div style={{
                                                                    fontSize: "14px"
                                                                }}>views</div>
                                                            </span>
                                                        </div>
                                                    </Link>

                                                </div>
                                            </>
                                        )
                                    })
                                }

                            </div>
                            <div className={classes.bottomButtons}>
                                <div className={classes.leftButton}>
                                    <NavLink to={property.agency_detail.website}>
                                        <img src={TvIcon} width={17} height={23} />
                                    </NavLink>
                                    <NavLink to={property.agency_detail.facebook}>
                                        <img src={FacebookIcon} width={17} height={23} />

                                    </NavLink>
                                    <NavLink to={property.agency_detail.instagram}>
                                        <img src={InstagramIcon} width={17} height={23} />
                                    </NavLink>
                                    <NavLink to={property.agency_detail.linkedin}>
                                        <img src={LinkedinIcon} width={17} height={23} />
                                    </NavLink>



                                </div>
                                <div className={classes.rightButton}>
                                    <button className={classes.editButton} onClick={() => handlennavigate(property.id)}>
                                        <img src={EditIcon} alt="edit" />
                                        <span>Beheren</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* </div> */}

                {/* <div className={classes.promoBanner}>
                    <div className={classes.promoContent}>
                        <h2>Val op met jouw eigen magazine!</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className={classes.magazineMockup}>
                        <img src="/magazine.png" alt="Magazine mockup" />
                    </div>
                </div> */}
                <div className={classes.bannersAdded}>
                    {
                        Dashboard1 &&
                        Dashboard1?.url_original_file && (
                            <img
                                // fluid={true}
                                src={Dashboard1?.url_original_file}
                                alt={Dashboard1?.original_file_name}

                            />
                        )
                    }
                    {
                        Dashboard2 &&
                        Dashboard2?.url_original_file && (
                            <img
                                // fluid={true}
                                src={Dashboard2?.url_original_file}
                                alt={Dashboard2?.original_file_name}

                            />
                        )

                    }
                    {
                        Dashboard3 &&
                        Dashboard3?.url_original_file && (
                            <img
                                // fluid={true}
                                src={Dashboard3?.url_original_file}
                                alt={Dashboard3?.original_file_name}

                            />
                        )

                    }
                </div>
            </div>
        </main>
    );
};

export default BrokerDashboardMain; 