import React, { useState } from "react";
import classes from "./suppliercardselectable.module.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import NoDataFound from "../../NoDataFound";
import { SUPPLIER_BUDGETS } from "../../../Constants";
import { useTranslation } from "react-i18next";
import PriceFormatter from "../../../utils/PriceFormatter";

const SupplierCardSelectable = ({
  region,
  suppliers,
  changeSupplier,
  handleDeleteSupplier,
  changeBudget,
}) => {
  const location = useLocation().pathname;
  const platform = localStorage.getItem("Platform");
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className={classes.SupplierListingWrap}>
        <div className={classes.grid_2}>
          {suppliers && suppliers.length > 0
            ? suppliers.map((item, index) => (
                <div
                  className={classes.checkbox_wrapper}
                  key={`supplier_${index}`}
                >
                  <label>
                    <input
                      type="checkbox"
                      id={`${region}_${item.id}`}
                      name={`${region}[]`}
                      className={classes.radioCustom}
                      value={item.id}
                      onChange={(event) => {
                        changeSupplier(event, region, item);
                      }}
                      defaultChecked={true}
                    />
                    <span>
                      {t(item.name)}
                      {/* {` (€ ${PriceFormatter(
                        item.price_tables.map((ptItem) =>
                          ptItem.price_lines
                            .map((plItem) => parseFloat(plItem.price))
                            .reduce((accumulator, currentValue) => {
                              return accumulator + currentValue;
                            }, 0)
                        ),
                        2
                      ).replace(",00", ",-")})`} */}
                    </span>
                  </label>
                </div>
              ))
            : t("banner_choose_region_first")}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupplierCardSelectable;
