import React from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import MyAddBannerLayout from "../layouts/MyAddBannerLayout";
import classes from "./addbanner.module.scss";

const AddBannerMain = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <MyAddBannerLayout />
    </React.Fragment>
  );
};

export default AddBannerMain;
