import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";
import React from "react";
import OtpPage from "../pages/AuthPages/OtpPage";
import HomePage from "../pages/HomePage";
import ResetPasswordPage from "../pages/AuthPages/ResetPasswordPage";
import MyListingPage from "../pages/MyListingPage";
import MyBannersPage from "../pages/MyBannersPage";
import ChangeAccountInfoPage from "../pages/ChangeAccountInfoPage";
import FavouritePropretyPage from "../pages/FavouritePropretyPage";
import SearchPage from "../pages/SearchPage";
import LoginPage from "../pages/AuthPages/LogingPage";
import CreateAccountPage from "../pages/AuthPages/CreateAccountPage";
import ForgotPasswordPage from "../pages/AuthPages/ForgotPasswordPage";
import AccountCreatedPage from "../pages/AuthPages/AccountCreatedPage";
import SavedFilterPage from "../pages/SavedFilterPage/SavedFilterPage";
import ConnectionPage from "../pages/ConnectionPage/index";
import AddListingPage from "../pages/AddListingPage";
import AddListingPageTest from "../pages/AddListingPageTest";
import AddedOrganisationPage from "../pages/AddedOrganisationPage";
import MyOrganisationListPage from "../pages/MyOrganisationListPage";
import AddOrganisationPage from "../pages/AddOrganisationPage";
import ViewSingleOrganisationPage from "../pages/ViewSingleOrganisationPage";
import CongratulationsPage from "../pages/CongratulationsPage";
import JoinOrganisationPage from "../pages/JoinOrganisationPage";
import AddEmployeePage from "../pages/AddEmployeePage";
import ViewListingPage from "../pages/ViewListingPage";
import ViewPropertyBannerPage from "../pages/ViewPropertyBannerPage";
import ViewOrgPage from "../pages/ViewOrgPage";
import ViewListingPhotos from "../components/ViewListingMain/ViewListingPhotos";
import EditListingPage from "../pages/EditListingPage";
import CMSPage from "../pages/CMSPage";
import ErrorPage from "../pages/ErrorPage";
import MembershiPricingPage from "../pages/MembershiPricingPage";
import CreateOrgAccountPage from "../pages/CreateOrgAccountPage";
import MembershipPage from "../pages/MembershipPage";
import ThankyouPage from "../pages/ThankyouPage";
import ComingSoonPage from "../pages/ComingSoonPage";
import VerificationPage from "../pages/VerificationPage/VerificationPage";
import SearchOrganisationPage from "../pages/SearchOrganisationPage";
import EditBannerPage from "../pages/EditBannerPage";
import AddBannerPage from "../pages/AddBannerPage";
import MailAddBannerPage from "../pages/MailAddBannerPage";
import PaymentVerificationPage from "../pages/PaymentVerificationPage/PaymentVerificationPage";
import PaymentFailedPage from "../pages/PaymentFailedPage";
import CreateOrganisationAccountPage from "../pages/CreateOrganisationAccountPage";
import ViewOrganisationPage from "../pages/ViewOrganisationPage";
import BannerImage from "../pages/BannerImage";
import StopCampaignPage from "../pages/StopCampaignPage";
import MyInvoicesPage from "../pages/MyInvoicesPage";
import MolliePaymentPage from "../pages/MolliePaymentPage";
import AbroadPage from "../pages/Abroad";
import MyMagazinesPage from "../pages/MyMagazinesPage";
import AddMagazinePage from "../pages/AddMagazinePage";
import EditMagazinePage from "../pages/EditMagazinePage";
import BrokerDashboard from "../pages/BrokerDashboard";

const Private = (props) => {
  const authorizeToken = localStorage.getItem("authToken");
  const auth = authorizeToken && authorizeToken !== "" ? true : false;
  return auth ? <props.Component /> : <Navigate to="/login" />;
};
const Public = (props) => {
  //   const authorizeToken = localStorage.getItem("authToken");
  //   const auth = !authorizeToken || authorizeToken == "" ? true : false;
  return <props.Component />;
};
export const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route>
        <Route path="/" element={<Public Component={HomePage} />} />
        <Route path="/login" element={<Public Component={LoginPage} />} />
        <Route
          path="/create-account"
          element={<Public Component={CreateAccountPage} />}
        />
        <Route
          path="/forgot-password"
          element={<Public Component={ForgotPasswordPage} />}
        />
        <Route
          path="/account-created"
          element={<Private Component={AccountCreatedPage} />}
        />
        <Route path="/otp" element={<Public Component={OtpPage} />} />
        <Route path="/home" element={<Public Component={HomePage} />} />
        <Route
          path="/reset-password"
          element={<Public Component={ResetPasswordPage} />}
        />
        <Route
          path="/my-listing"
          element={<Private Component={MyListingPage} />}
        />
        <Route
          path="/my-banners"
          element={<Private Component={MyBannersPage} />}
        />
        <Route
          path="/favourites"
          element={<Private Component={FavouritePropretyPage} />}
        />
        <Route
          path="/saved-filter"
          element={<Private Component={SavedFilterPage} />}
        />
        <Route
          path="/connections"
          element={<Private Component={ConnectionPage} />}
        />
        <Route
          path="/my-organisations"
          element={<Private Component={MyOrganisationListPage} />}
        />
        <Route path="/search" element={<Public Component={SearchPage} />} />
        <Route
          path="/change-account-info"
          element={<Private Component={ChangeAccountInfoPage} />}
        />
        <Route
          path="/add-listing"
          element={<Public Component={AddListingPage} />}
        />
        <Route
          path="/add-listing-v1"
          element={<Public Component={AddListingPageTest} />}
        />
        <Route
          path="/added-an-organisation"
          element={<Private Component={AddedOrganisationPage} />}
        />
        <Route
          path="/add-an-organisation"
          element={<Private Component={AddOrganisationPage} />}
        />
        <Route
          path="/view-employee/:id"
          element={<Private Component={ViewSingleOrganisationPage} />}
        />
        <Route
          path="/congratulations"
          element={<Private Component={CongratulationsPage} />}
        />
        <Route
          path="/join-organisation"
          element={<Private Component={JoinOrganisationPage} />}
        />
        <Route
          path="/add-employee"
          element={<Private Component={AddEmployeePage} />}
        />
        <Route
          path="/view-listing/:city/:address/:id"
          element={<Public Component={ViewListingPage} />}
        />
        <Route
          path="/view-org/:city/:orgname/:id"
          element={<Public Component={ViewOrgPage} />}
        />
        <Route
          path="/joined-an-organisation"
          element={<Private Component={AddedOrganisationPage} />}
        />
        <Route
          path="/view-listing-photo/:city/:address/:id"
          element={<Public Component={ViewListingPhotos} />}
        />
        <Route
          path="/edit-listing/:id"
          element={<Private Component={EditListingPage} />}
        />
        <Route
          path="/cms-page/:slug"
          element={<Public Component={CMSPage} />}
        />
        <Route path="*" element={<Public Component={ErrorPage} />} />
        <Route
          path="/membership-pricing"
          element={<Public Component={MembershiPricingPage} />}
        />
        {/* <Route
          path="/create-org-account-step1"
          element={<Public Component={CreateOrgAccountPage} />}
        />
        <Route
          path="/create-org-account-step2/:id"
          element={<Public Component={CreateOrgAccountPage} />}
        /> */}
        <Route
          path="/create-organisation-account-step1"
          element={<Public Component={CreateOrganisationAccountPage} />}
        />
        <Route
          path="/create-org-account-step2/:id"
          element={<Public Component={CreateOrganisationAccountPage} />}
        />
        <Route
          path="/membership/:id"
          element={<Public Component={MembershipPage} />}
        />
        <Route path="/thankyou" element={<Public Component={ThankyouPage} />} />
        <Route
          path="/coming-soon"
          element={<Public Component={ComingSoonPage} />}
        />
        <Route
          path="/add-banner"
          element={<Private Component={AddBannerPage} />}
        />
        <Route
          path="/mail-add-banner"
          element={<Public Component={MailAddBannerPage} />}
        />
        <Route
          path="/edit-banner/:id"
          element={<Private Component={EditBannerPage} />}
        />
        <Route
          path="view-banner/:id"
          element={<Public Component={ViewPropertyBannerPage} />}
        />
      </Route>
      <Route
        path="/verification/:key"
        element={<Public Component={VerificationPage} />}
      />

      <Route
        path="/edit-an-organisation/:id"
        element={<Private Component={AddOrganisationPage} />}
      />
      <Route
        path="/search-organisation"
        element={<Public Component={SearchOrganisationPage} />}
      />
      <Route
        path="/verify-payment-status"
        element={<Public Component={PaymentVerificationPage} />}
      />
      <Route
        path="/payment-failed"
        element={<Public Component={PaymentFailedPage} />}
      />
      <Route
        path="/view-organisation/:city/:orgname/:id"
        element={<Public Component={ViewOrganisationPage} />}
      />
      <Route
        path="/banner-image/:id"
        element={<Public Component={BannerImage} />}
      />
      <Route
        path="/stop-campaign"
        element={<Public Component={StopCampaignPage} />}
      />
      <Route
        path="/my-invoices"
        element={<Private Component={MyInvoicesPage} />}
      />
      <Route
        path="/mollie-payment"
        element={<Private Component={MolliePaymentPage} />}
      />
      <Route
        path="/abroad"
        element={<Public Component={AbroadPage} />}
      />
       <Route path="/home/:country" element={<Public Component={HomePage} />} />
      <Route
        path="/my-magazines"
        element={<Private Component={MyMagazinesPage} />}
      />
      <Route
        path="/my-magazines/new"
        element={<Private Component={AddMagazinePage} />}
      />
      <Route
        path="/my-magazines/:id/edit"
        element={<Private Component={EditMagazinePage} />}
      />
      <Route
        path="/broker-dashboard"
        element={<Private Component={BrokerDashboard} />}
      />
    </React.Fragment>
  )
);
