import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classes from "./mymagazineform.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  actionGetMagazineApiCall,
  actionModifyPlacementApiCall,
  actionModifyMagazineTextApiCall,
} from "../../../Redux/Actions";
import PriceFormatter from "../../../utils/PriceFormatter";
import { DateTime } from "luxon";
import { DropDownSelector } from "../../DropDownSelector";
import { MyListingApiCall } from "../../../API/API";
import Loader from "../../Loader";
import EditIcon from "../../../assets/icons/EditIcon.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import WordCount from "@ckeditor/ckeditor5-build-classic";

const MagazineForm = () => {
  const stripHTML = (htmlString) => {
    // Create a new DOMParser instance
    const parser = new DOMParser();
    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, "text/html");
    // Extract text content
    const textContent = doc.body.textContent || "";
    // Trim whitespace
    return textContent.trim();
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef();
  let editorRef = useRef();
  const [editorLoaded, setEditorLoaded] = useState(false);
  const { CKEditor, ClassicEditor } = editorRef.current || {};
  const ITEMS_PER_PAGE = 20;
  const count = useSelector(
    (state) => state.CommonReducer.myPropertyListingData?.count
  );
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumber, setPageNumber] = useState();
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [defaultText, setDefaultText] = useState("");
  const [defaultTextId, setDefaultTextId] = useState();
  const [defaultItem, setDefaultItem] = useState();
  const [maxChars, setMaxChars] = useState(100);
  const [isLimitExceeded, setIsLimitExceeded] = useState(
    stripHTML(defaultText).length > maxChars
  );
  const [isLimitClose, setIsLimitClose] = useState(
    !isLimitExceeded && stripHTML(defaultText).length > maxChars * 0.8
  );
  const [search, setSearch] = useState();

  const [myPropertyListingData, setMyPropertyListingData] = useState([]);

  const urlArr = window.location.pathname.split("/");

  const id = urlArr[2];

  const magazineData = useSelector(
    (state) => state.CommonReducer.saveMagazineData
  );

  const name = magazineData?.name;
  const date = DateTime.fromISO(magazineData?.publication_date).toFormat(
    "dd-LL-yyyy"
  );
  const deadline = DateTime.fromISO(magazineData?.deadline).toFormat(
    "dd-LL-yyyy HH:mm"
  );
  const productionDate = DateTime.fromISO(
    magazineData?.production_date
  ).toFormat("dd-LL-yyyy");
  const pages = magazineData?.magazine_pages.length;

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    magazineData?.magazine_placements
      ? magazineData?.magazine_placements.map((item) => item.property_id)
      : []
  );
  const [selectedTypes, setSelectedTypes] = useState({});
  const [agencyIds, setAgencyIds] = useState(
    magazineData?.magazine_type?.magazine_members
      ? magazineData?.magazine_type?.magazine_members.map(
          (item) => item.agency_id
        )
      : []
  );

  const handleCheckboxChange = (event) => {
    console.log("handleCheckboxChange", event);
    const value = event.target.value;
    const typeInput = document.getElementById(`ak_${value}`);
    let data = {};
    if (event.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, parseInt(value)]);
      let tmpTypes = selectedTypes;
      if (typeof tmpTypes[parseInt(value)] === "undefined") {
        tmpTypes[parseInt(value)] = typeInput.options[1]?.value;
        setSelectedTypes(tmpTypes);
      }
      data = {
        checked: 1,
        property_id: value,
        ad_kind_id: typeInput.value,
        magazine_id: magazineData?.id,
      };
    } else {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== parseInt(value))
      );
      data = {
        checked: 0,
        property_id: value,
        ad_kind_id: typeInput.value,
        magazine_id: magazineData?.id,
      };
    }
    dispatch(actionModifyPlacementApiCall(data));
    console.log("data checkbox", data);
  };

  const onChangeHandler = (event) => {
    console.log(event.target.selectedOptions[0].value);
    setSelectedCheckboxes(
      selectedCheckboxes.filter(
        (item) => item !== parseInt(`${event.target.id.replace("ak_", "")}`)
      )
    );
    setSelectedCheckboxes([
      ...selectedCheckboxes,
      parseInt(`${event.target.id.replace("ak_", "")}`),
    ]);
    let tmpTypes = selectedTypes;
    tmpTypes[event.target.id.replace("ak_", "")] = parseInt(
      event.target.selectedOptions[0].value
    );
    console.log("tmpTypes", tmpTypes);
    setSelectedTypes(tmpTypes);
    const data = {
      checked: 1,
      property_id: event.target.id.replace("ak_", ""),
      ad_kind_id: event.target.selectedOptions[0].value,
      magazine_id: magazineData?.id,
    };
    dispatch(actionModifyPlacementApiCall(data));
    console.log("data", data);
    console.log("selectedTypes", selectedTypes);
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, //Added .CKEditor
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
    setEditorLoaded(true);
    if (myPropertyListingData.length === 0) {
      handlePageChange(currentPage);
    }
    if (id && typeof id !== "undefined") {
      let body = { id: id };
      dispatch(actionGetMagazineApiCall(body));
      let tmpTypes = selectedTypes;
      for (let i = 0; i < magazineData?.magazine_placements.length; i++) {
        tmpTypes[magazineData?.magazine_placements[i].property_id] =
          magazineData?.magazine_placements[i].ad_kind_id;
      }
      setSelectedTypes(tmpTypes);
    }
    const pageNumbers = [];
    let totalPages = Math.ceil(count / ITEMS_PER_PAGE);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    setTotalPages(totalPages);
    setPageNumber(pageNumbers);
  }, [count, currentPage, dispatch, id, navigate, selectedTypes]);

  const handlePageChange = async (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    setActivePage(pageNumber);
    const body = {
      page: pageNumber,
      per_page: ITEMS_PER_PAGE,
      search: search,
    };
    const properties = await MyListingApiCall(body);
    setMyPropertyListingData(properties.data.data.propertyData);
    setLoading(false);
  };
  const pagination = () => {
    let ui = [];
    for (
      let i = activePage <= 4 ? 1 : activePage - 4;
      i <= (activePage <= 4 ? 8 : activePage + 4);
      i++
    ) {
      if (i <= pageNumber.length) {
        ui.push(
          <div key={i} className={classes.page}>
            <span
              onClick={() => {
                handlePageChange(i);
              }}
              className={activePage === i ? classes.Active : classes.InActive}
            >
              {i}
            </span>
          </div>
        );
      }
    }
    myRef?.current?.scrollIntoView({ behavior: "smooth" });
    return ui;
  };
  const changeToDefaultPage = () => {
    setCurrentPage(1);
    setActivePage(1);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = () => {
    const text = editorRef.editor.getData();
    const data = {
      magazine_id: magazineData.id,
      property_id: defaultItem.id,
      ad_kind_id: selectedTypes[defaultItem.id],
      description: text,
    };
    console.log("data", data);
    dispatch(actionModifyMagazineTextApiCall(data));
    setShow(false);
  };

  const handleReset = async () => {
    const text = await getText(defaultItem);
    setDefaultText(text);
    editorRef.editor.setData(text);
  };

  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "numberedList",
        "bulletedList",
        "|",
        "undo",
        "redo",
      ],
      styles: {
        height: "500px",
      },
    },
    language: "nl",
    extraPlugins: [WordCount],
    wordCount: {
      onUpdate: (stats) => {
        // Prints the current content statistics.
        console.log(`Characters: ${stats.characters}\nWords: ${stats.words}`);
      },
    },
  };

  const getClassName = (item) => {
    if (selectedCheckboxes.includes(item?.id)) {
      const selectedType = parseInt(selectedTypes[item?.id]);
      const ad_kind = magazineData?.magazine_type.magazine_ad_kinds.filter(
        (e) => e.id === selectedType
      )[0];
      console.log(magazineData?.magazine_placements);
      const properties = magazineData?.magazine_placements.filter(
        (mp) => mp.property?.id === item.id
      );
      if (properties && properties.length > 0) {
        const property = properties[0].property;
        if (stripHTML(getText(property)).length > ad_kind?.max_text_length) {
          return classes.PropertyRowOverflow;
        }
      }
      console.log(properties);
      return classes.PropertyRowActive;
    }
    return classes.PropertyRow;
  };

  const getText = (property) => {
    const placements = magazineData?.magazine_placements.filter(
      (item) => item.property_id === property.id
    );
    if (placements && placements.length > 0) {
      const ad_kind = placements[0].ad_kind;
      const pProperty = placements[0].property;
      return pProperty.property_magazine_texts &&
        pProperty.property_magazine_texts.length > 0
        ? pProperty.property_magazine_texts.filter(
            (item) => item.ad_kind_id === ad_kind.id
          )[0].description
        : property.description;
    }
  };
  const getTextId = (property) => {
    const placements = magazineData?.magazine_placements.filter(
      (item) => item.property_id === property.id
    );
    if (placements && placements.length > 0) {
      const ad_kind = placements[0].ad_kind;
      const pProperty = placements[0].property;
      const mText = pProperty.property_magazine_texts.filter(
        (item) => item.ad_kind_id === ad_kind.id
      )[0];
      return mText?.id;
    }
  };

  return (
    <React.Fragment>
      <div className={classes.ConnectionContainer}>
        <div>
          {magazineData && (
            <div className={classes.MagazineContainer}>
              <div>
                <p>{t("magazine_name")}</p>
                <p>{name}</p>
              </div>
              <div>
                <p>{t("magazine_date")}</p>
                <p>{date}</p>
              </div>
              <div>
                <p>{t("magazine_deadline")}</p>
                <p>{deadline}</p>
              </div>
              <div>
                <p>{t("magazine_production_date")}</p>
                <p>{productionDate}</p>
              </div>
              <div>
                <p>{t("magazine_status")}</p>
                <p>{magazineData.status}</p>
              </div>
              <div>
                <p>{t("magazine_pages")}</p>
                <p>{pages}</p>
              </div>
            </div>
          )}
          <div className={classes.PropertiesContainer}>
            {loading === true ? (
              <div className={classes.LoaderWrap}>
                <Loader />
              </div>
            ) : myPropertyListingData?.rows &&
              myPropertyListingData?.rows?.length > 0 ? (
              myPropertyListingData?.rows?.map((item, index) => (
                <>
                  {index === 0 && (
                    <div>
                      <input
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          handlePageChange(currentPage);
                        }}
                      ></input>
                    </div>
                  )}
                  <div key={item.id} className={getClassName(item)}>
                    <div>
                      {" "}
                      <div className={classes.inputbox}>
                        <input
                          type="checkbox"
                          id={item.id}
                          name={item.name}
                          className={classes.radioCustom}
                          value={item.id}
                          onChange={handleCheckboxChange}
                          checked={selectedCheckboxes.includes(item?.id)}
                        />
                        <label
                          className={classes.radioCustomLabel}
                          htmlFor={item.id}
                        >
                          <span> </span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <DropDownSelector
                        width="160px"
                        name="ad_kind"
                        placeholder={t("choose_ad_kind")}
                        id={`ak_${item.id}`}
                        value={
                          selectedTypes[item.id] !== ""
                            ? selectedTypes[item.id]
                            : 0
                        }
                        onChange={onChangeHandler}
                        data={magazineData?.magazine_type?.magazine_ad_kinds?.map(
                          (kind) => {
                            return {
                              id: kind.id,
                              value: kind.id,
                              translation: kind.name,
                            };
                          }
                        )}
                        className={classes.select}
                      />
                    </div>
                    <div>{item.address}</div>
                    <div>
                      {item.city
                        .split(" ")
                        .map(
                          (w) =>
                            w[0].toUpperCase() + w.substring(1).toLowerCase()
                        )
                        .join(" ")}
                    </div>
                    {item.is_sell === 1 && (
                      <div>
                        {`€ ${PriceFormatter(item.sell_price, 2).replace(
                          ",00",
                          ",-"
                        )}`}{" "}
                        {t(item.sell_postfix)}
                      </div>
                    )}
                    {item.is_rent === 1 && (
                      <div>
                        {`€ ${PriceFormatter(item.rent_price, 2).replace(
                          ",00",
                          ",-"
                        )}`}{" "}
                        {t(item.rent_postfix)}
                      </div>
                    )}
                    <div>
                      {" "}
                      <img
                        width={15}
                        src={EditIcon}
                        alt="Tekst bewerken"
                        onClick={() => {
                          const mText = getText(item);
                          const mTextId = getTextId(item);
                          setDefaultItem(item);
                          setDefaultTextId(mTextId);
                          setDefaultText(mText);
                          const l = stripHTML(mText).length;
                          setIsLimitExceeded(l > maxChars);
                          setIsLimitClose(l <= maxChars && l > maxChars * 0.8);
                          setShow(true);
                        }}
                      />
                    </div>
                  </div>
                </>
              ))
            ) : (
              ""
            )}
          </div>
          <div className={classes.Pagination}>
            <div className={classes.PageWrap}>
              {currentPage > 1 && currentPage <= totalPages && (
                <div
                  className={
                    activePage !== 1
                      ? classes.NextPageActive
                      : classes.NextPageInActive
                  }
                  onClick={() => {
                    if (activePage !== 1) {
                      setCurrentPage(currentPage - 1);
                      setActivePage(currentPage - 1);
                      handlePageChange(currentPage - 1);
                    }
                  }}
                >
                  {"<"} <span>{t("prev_page")}</span>
                </div>
              )}
              {pageNumber?.length >= 1 && (
                <span className={classes.pageText}>{t("page")}</span>
              )}
              {pageNumber?.length > 0 && pagination()}
              {pageNumber?.length > 8 && (
                <div className={classes.page}>
                  {" "}
                  <span className={classes.pageText}>{t("of")}</span>
                  <span
                    onClick={() => {
                      handlePageChange(pageNumber.length);
                    }}
                    className={
                      activePage === pageNumber.length
                        ? classes.Active
                        : classes.InActive
                    }
                  >
                    {pageNumber.length}
                  </span>
                </div>
              )}
            </div>
            {currentPage < totalPages && (
              <div
                className={
                  activePage !== pageNumber.length - 1
                    ? classes.NextPageActive
                    : classes.NextPageInActive
                }
                onClick={() => {
                  if (activePage !== pageNumber.length) {
                    setCurrentPage(+currentPage + 1);
                    setActivePage(+currentPage + 1);
                    handlePageChange(currentPage + 1);
                  }
                }}
              >
                <span>{t("next_page")}</span> {">"}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.DemoUpdate} id="DemoUpdate">
            {editorLoaded ? (
              <CKEditor
                ref={(el) => (editorRef = el)}
                name="description"
                editor={ClassicEditor}
                config={editorConfig}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log(data.length, data);
                  const container = document.getElementById("DemoUpdate");
                  const progressCircle = document.getElementById(
                    "DemoUpdateChartCircle"
                  );
                  const charactersBox = document.getElementById(
                    "DemoUpdateChartCharacters"
                  );
                  const circleCircumference = Math.floor(
                    2 * Math.PI * progressCircle.getAttribute("r")
                  );
                  const l = stripHTML(data).length;
                  const charactersProgress =
                    (l / maxChars) * circleCircumference;
                  setIsLimitExceeded(l > maxChars);
                  setIsLimitClose(!isLimitExceeded && l > maxChars * 0.8);
                  const circleDashArray = Math.min(
                    charactersProgress,
                    circleCircumference
                  );

                  // Set the stroke of the circle to show how many characters were typed.
                  progressCircle.setAttribute(
                    "stroke-dasharray",
                    `${circleDashArray},${circleCircumference}`
                  );

                  // Display the number of characters in the progress chart. When the limit is exceeded,
                  // display how many characters should be removed.
                  if (isLimitExceeded) {
                    charactersBox.textContent = `-${
                      stripHTML(data).length - maxChars
                    }`;
                  } else {
                    charactersBox.textContent = stripHTML(data).length;
                  }
                }}
                data={defaultText || ""}
              />
            ) : (
              ""
            )}
            <div className={classes.DemoUpdateControls} id="DemoUpdateControls">
              <svg
                id="DemoUdateChart"
                className={classes.DemoUdateChart}
                viewBox="0 0 40 40"
                width="40"
                height="40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  stroke="hsl(0, 0%, 93%)"
                  stroke-width="3"
                  fill="none"
                  cx="20"
                  cy="20"
                  r="17"
                ></circle>
                <circle
                  id="DemoUpdateChartCircle"
                  className={
                    isLimitExceeded
                      ? classes.DemoUpdateChartCircleLimitExceeded
                      : isLimitClose
                      ? classes.DemoUpdateChartCircleLimitClose
                      : classes.DemoUpdateChartCircle
                  }
                  stroke="hsl(202, 92%, 59%)"
                  stroke-width="3"
                  stroke-dasharray="106,106"
                  stroke-linecap="round"
                  fill="none"
                  cx="20"
                  cy="20"
                  r="17"
                ></circle>
                <text
                  id="DemoUpdateChartCharacters"
                  className={classes.DemoUpdateChartCharacters}
                  x="50%"
                  y="50%"
                  dominant-baseline="central"
                  text-anchor="middle"
                >
                  {isLimitExceeded
                    ? `-${stripHTML(defaultText).length - maxChars}`
                    : stripHTML(defaultText).length}
                </text>
              </svg>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReset}>
            Tekst opnieuw instellen
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Sluiten
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Opslaan
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default MagazineForm;
