import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainLayout from "../layouts/MainLayout";
import classes from "./mybannersmain.module.scss";
import pinkPlus from "../../assets/icons/pinkPlus.svg";
import logoIcon from "../../assets/icons/smallLogo.svg";
import fbIcon from "../../assets/icons/bannerFacebookIcon.svg";
import instaIcon from "../../assets/icons/bannerInstagramIcon.svg";
import OnlineIcon from "../../assets/icons/bannerWorldIcon.svg";
import spreadIcon from "../../assets/icons/bannerSpreadIcon.svg";
import sprintIcon from "../../assets/icons/bannerSprintIcon.svg";
import clickIcon from "../../assets/icons/bannerClicksIcon.svg";
import viewIcon from "../../assets/icons/bannerViewsIcon.svg";
import { AiOutlineStop } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit, FaEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ModalMain from "../common/Modal";
import {
  actionBannerCampaignsApiCall,
  actionSaveBannerDetails,
  actionDeletePropertyBannerApiCall,
  actionMakeBannerStopApiCall,
  actionViewBannerDetailsApiCall,
} from "../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../layouts/ConfirmationModal";
import { DateTime } from "luxon";
import { updateLocale } from "moment";
import { BannerCampaignsApiCall } from "../../API/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { far, fat, fal, fab } from "@awesome.me/kit-0ea32a987c/icons";

const MyBannerCampaignsMain = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showInfoScreen, setShowInfoScreen] = useState(false);
  const [showTitle, setShowTitle] = useState();
  const [showContent, setShowContent] = useState();
  const [showModel, setShowModel] = useState(false);
  const [id, setId] = useState();
  const icons = {
    ONLINE: OnlineIcon,
    FACEBOOK: fbIcon,
    INSTAGRAM: instaIcon,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bannerCampaignsData = useSelector(
    (state) => state.CommonReducer.myBannerCampaignsData
  );

  const [campaigns, setCampaigns] = useState(bannerCampaignsData);

  console.log(campaigns);

  const myPropertyListingData = useSelector(
    (state) => state.CommonReducer.myPropertyListingData
  );

  useEffect(() => {
    let body = { navigate };
    // const result = BannerCampaignsApiCall().then(function(res){
    //   setCampaigns(res.data.data);
    //   console.log("then", res);
    // });
    // console.log("result", result);
    dispatch(actionSaveBannerDetails(null));
    dispatch(actionBannerCampaignsApiCall(body));
    // setCampaigns(bannerCampaignsData);
  }, []);

  const platform = localStorage.getItem("Platform");

  const updateCampaign = (id) => {
    let tmpCampaigns = [];
    for (let i = 0; i < campaigns.length; i++) {
      let tmpCampaign = campaigns[i];
      if (tmpCampaign.id === id) {
        tmpCampaign.end_date = DateTime.now().minus({
          days: 1,
        });
      }
      tmpCampaigns.push(tmpCampaign);
    }
    setCampaigns(tmpCampaigns);
  };

  const showInfo = (campaignId, templateId) => {
    setShowContent(
      `<iframe title="preview" src="https://woningmarkt.s3.amazonaws.com/${
        platform !== "PROD" ? "development" : "production"
      }/templates/${templateId}/index.html?banner=${campaignId}" height="90px" width="728px"></iframe>`
    );
    setShowTitle(`Code voor Campagne`);
    setShowInfoScreen(true);
  };

  const RemovePropertyBannerHandler = () => {
    let data = {
      id: id,
    };
    let body = { data, navigate };
    dispatch(actionDeletePropertyBannerApiCall(body));
  };

  return (
    <React.Fragment>
      <section className={classes.woningcampagnes}>
        <div className={classes.container}>
          <div className={classes.flex}>
            <h3>Woning campagnes</h3>
            <div className={classes.buttons}>
              <a className={classes.button} href="/my-banners">
                <FontAwesomeIcon icon={far.faEye} /> Alle bekijken
              </a>
              <a className={classes.button} href="/add-banner">
                <FontAwesomeIcon icon={far.faRocket} /> Start nieuw campagne
              </a>
            </div>
          </div>
          <div className={classes.grid}>
            <a href="/add-banner" className={classes.addnewitem}>
              <div className={classes.content}>
                <FontAwesomeIcon icon={far.faRocket} />
                <p>Nieuw campagne toevoegen</p>
              </div>
            </a>
            {bannerCampaignsData?.length > 0 &&
              bannerCampaignsData.map((item) => {
                return (
                  <a href="" className={classes.item}>
                    <div className={classes.image}>
                      {item.listing_auto_select === false &&
                        item.banner_campaign_listing &&
                        item.banner_campaign_listing.length > 0 &&
                        item.banner_campaign_listing.map((property) => {
                          if (
                            property.listing_property &&
                            property.listing_property.image_list.length > 0
                          ) {
                            return (
                              <img
                                className={classes.BannerImage}
                                src={
                                  property.listing_property.image_list[0]
                                    .url_thumb_file
                                }
                                alt={
                                  property.listing_property.image_list[0]
                                    .original_file_name
                                }
                              />
                            );
                          } else {
                            return <p></p>;
                          }
                        })}
                      {item.listing_auto_select === true &&
                        myPropertyListingData?.rows
                          ?.filter(
                            (property) => property.agency_id === item.agency_id
                          )
                          .filter((property) => property.image_list.length > 0)
                          .slice(0, 1)
                          .map((property) => {
                            return (
                              <img
                                className={classes.BannerImage}
                                src={property.image_list[0].url_thumb_file}
                                alt={property.image_list[0].original_file_name}
                              />
                            );
                          })}{" "}
                      <div className={classes.tags}>
                        {item.campaign_banners.map((cp) => {
                          return (
                            <>
                              <div className={classes.tag}>
                                <FontAwesomeIcon icon={fal.faArrowPointer} />
                                <span>
                                  <span className={classes.number}>
                                    {cp.property_banner.click_count || 0}
                                  </span>
                                  <span className={classes.label}>CTR</span>
                                </span>
                              </div>
                              <div className={classes.tag}>
                                <FontAwesomeIcon icon={fal.faEye} />
                                <span>
                                  <span className={classes.number}>
                                    {cp.property_banner.view_count || 0}
                                  </span>
                                  <span className={classes.label}>views</span>
                                </span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className={classes.content}>
                      <div className={classes.flex}>
                        <div className={classes.socialicons}>
                          <FontAwesomeIcon icon={fal.faDesktop} />
                          <FontAwesomeIcon icon={fab.faFacebook} />
                          <FontAwesomeIcon icon={fab.faInstagram} />
                          <FontAwesomeIcon icon={fab.faLinkedin} />
                        </div>
                        <a
                          className={classes.button}
                          href={`/edit-banner/${item.id}`}
                        >
                          <FontAwesomeIcon icon={fal.faPenToSquare} /> Beheren
                        </a>
                      </div>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      </section>

      {/* <div className={classes.ConnectionContainer}>
        <div className={classes.TitleWrap}>
          <h1>{t("my_banners")}</h1>
        </div>
        <div>
          {bannerCampaignsData?.length > 0 &&
            bannerCampaignsData.map((item) => {
              return (
                <div className={classes.BannerContainer}>
                  {!item.active && (
                    <div className={classes.Overlay}>
                      <p>{t("banner_expired")}</p>
                    </div>
                  )}
                  <div className={classes.Banner}>
                    <div className={classes.Left}>
                      {item.title}
                      <div className={classes.BannerImageContainer}></div>
                    </div>
                    <div className={classes.Middle}>
                      {item.campaign_banners.map((cp) => {
                        return (
                          <div className={classes.FilledDiv}>
                            <div className={classes.BannerTitle}>
                              <img
                                src={
                                  icons[
                                    cp.property_banner.banner_template
                                      .banner_format.banner_type.name
                                  ]
                                }
                                alt="Online"
                              />{" "}
                              {
                                cp.property_banner.banner_template.banner_format
                                  .banner_type.name
                              }{" "}
                              {
                                cp.property_banner.banner_template.banner_format
                                  .name
                              }
                            </div>
                            <div className={classes.BannerNumbers}>
                              {cp.property_banner.click_count}{" "}
                              <img
                                src={clickIcon}
                                alt="Clicks"
                                title="Clicks"
                              />
                              {cp.property_banner.view_count}{" "}
                              <img src={viewIcon} alt="Views" title="Views" />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className={classes.Right}>
                      <div className={classes.BudgetPerDay}>
                        <img src={logoIcon} alt="Woningmarkt" />{" "} */}
      {/* {item.campaign_budget[0].credits}{" "}
                          {t(item.campaign_budget[0].credit_period)} */}
      {/* </div>
                      <div className={classes.BudgetType}> */}
      {/* {item.campaign_budget[0]?.spending_type ===
                          "SPREAD_EVENLY" ? ( */}
      {/* <div>
                          <img src={spreadIcon} alt="Spread Evenly" /> Spread
                          Evenly
                        </div> */}
      {/* ) : (
                            <div></div>
                          )} */}
      {/* {item.campaign_budget[0]?.spending_type ===
                          "SPRINT_START" ? (
                            <div>
                              <img src={sprintIcon} alt="Sprint Start" /> Sprint
                              Start
                              <div>Run until</div>
                              <div>{item.campaign_budget[0]?.end_date}</div>
                            </div>
                          ) : (
                            <div></div>
                          )} */}
      {/* </div>
                      <div className={classes.EditCampaign}> */}
      {/* <FaEye
                            alt={t("edit_banner")}
                            onClick={() => {
                              showInfo(
                                item.id,
                                item.campaign_banner.banner_template.id
                              );
                            }}
                          />{" "} */}
      {/* <FaEdit
                          alt={t("edit_banner")}
                          onClick={() => {
                            let data = { id: item.id };
                            dispatch(actionSaveBannerDetails(null));
                            dispatch(actionViewBannerDetailsApiCall(data));
                            navigate(`/edit-banner/${item.id}`);
                          }}
                        />{" "}
                        <RiDeleteBin6Line
                          onClick={() => {
                            setId(item.id);
                            setShowModel(true);
                          }}
                        />
                        {item.active && (
                          <AiOutlineStop
                            onClick={() => {
                              let data = { id: item.id };
                              let body = { data, navigate };
                              dispatch(actionMakeBannerStopApiCall(body));
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div> */}
      {/* <div className={classes.IconWrapper}> 
            <div className={classes.IconContainer}>
              <p onClick={() => navigate("/add-banner")}>
                <img src={pinkPlus} alt={t("add_banner")} /> {t("add_banner")}
              </p>
            </div>
          </div>*/}
      {/* </div> */}
      <ModalMain
        showmodel={showInfoScreen}
        handleClose={() => setShowInfoScreen(false)}
        dialogClassName={classes.ModaleSize}
        title={showTitle}
        children={showContent}
      ></ModalMain>
      <ConfirmationModal
        showmodel={showModel}
        handleClose={() => setShowModel(false)}
        size={"sm"}
        onClickYesButton={RemovePropertyBannerHandler}
        title={`${t("are_you_sure_you_want_to_remove")}?`}
      />
    </React.Fragment>
  );
};

export default MyBannerCampaignsMain;
