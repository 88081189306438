import { put, call, select, takeEvery } from "redux-saga/effects";
import {
  AddOrganisationApiCall,
  AddOrganisationLogoApiCall,
  AddSavedFilterApiCall,
  EditAccountInfoApiCall,
  FavouritePropertyListingApiCall,
  ForgotPasswordAPICall,
  JoinAnOrganisationApiCall,
  LatestProperyListingHomeApiCall,
  LoginAPICall,
  LoginByTokenAPICall,
  LogoutApiCall,
  MakePropertyFavApiCall,
  MyListingApiCall,
  MyOrganisationListingApiCall,
  OtpAPICall,
  RegisterAPICall,
  RemoveJoinAnOrganisationApiCall,
  ResetPasswordApiCall,
  SearchOrganisationApiCall,
  UpdatePropertyVisibleApiCall,
  ViewSinglePropertyDetailsApiCall,
  ViewBannerDetailsApiCall,
  SavedFilterApiCall,
  AddPropertyApiCall,
  AddPropertyApiCallV1,
  AddBannerApiCall,
  UpdateBannerApiCall,
  BannerCampaignsApiCall,
  BannerTemplatesApiCall,
  UpdateImageApiCall,
  GetLanguagesApiCall,
  ChangeLanguageApiCall,
  GetOrganisationDetailApiCall,
  GetBannerApiCall,
  RemoveImagesApiCall,
  RemovePropertyPriceApiCall,
  GetPropertyTypeApiCall,
  GetApplicableOptionsApiCall,
  GetCitiesApiCall,
  GetRentPrefixApiCall,
  GetRentPostfixApiCall,
  GetSellPrefixApiCall,
  GetSellPostfixApiCall,
  GetApprovedOrganisationsApiCall,
  ViewSinglePropertyDetailApiCall,
  AddOrganisationEmployeeApiCall,
  GetEmployeeJobTitleApiCall,
  RemoveOrganisationEmployeeApiCall,
  GetSiteSettingsApiCall,
  ContactUsInfoApiCall,
  DeleteMyPropertyApiCall,
  DeletePropertyBannerApiCall,
  CreateOrgAccountWithoutLoginApiCall,
  CreateOrgAccountStep2ApiCall,
  VerificationApiCall,
  MolliePaymentApiCall,
  SearchOrganisationWithoutLoginApiCall,
  SubscriptionListApiCall,
  CreateUserSubsciptionApiCall,
  GetSubcriptionWithStatusApiCall,
  SelfOrganisationListingForSubscriptionApiCall,
  GetCityListApiCall,
  GetTranslationListApiCall,
  getCityInfoApiCall,
  GetAgencyBannerRecommendationsApiCall,
  GetAgencyBannerClickCountApiCall,
  GetAutoSuggestCityApiCall,
  GetSuppliersApiCall,
  MakeBannerStopApiCall,
  GetBannerIdApiCall,
  GetPropertyAllDataApiCall,
  MyInvoicesDataApiCall,
  GetInvoiceDataApiCall,
  GetAllCountriesApiCall,
  getCountryBannerApiCall,
  GetMagazinesApiCall,
  GetMagazineApiCall,
  AddMagazineApiCall,
  UpdateMagazineApiCall,
  DeleteMagazineApiCall,
  GetMagazineAdKindsApiCall,
  ModifyPlacementApiCall,
  GetTopMenuApiCall,
  GetMainMenuApicall,
  GetDahboardSliderApiCall,
  ModifyMagazineTextApiCall,
} from "../../API/API";
import {
  actionSavedFilterData,
  actionSaveForgotEmail,
  actionSaveLatestPropertyListingHome,
  actionSaveLoginToken,
  actionSaveMyOrganisationListing,
  actionSaveBannerCampaignsListing,
  actionSaveBannerTemplatesListing,
  actionSaveMyPropertyListing,
  actionSaveOtp,
  actionSaveSearchOrganisation,
  actionSaveSinglePropertyDetails,
  actionSaveBannerDetails,
  ACTION_ADD_ORGANISATION_API_CALL,
  ACTION_ADD_SAVED_FILTER_API_CALL,
  ACTION_EDIT_ACCOUNT_INFO_API_CALL,
  ACTION_FAVOURITE_PROPERTY_LISTING_API_CALL,
  ACTION_JOIN_ORGANISATION_API_CALL,
  ACTION_LATEST_PROPERTY_LISTING_HOME,
  ACTION_LOGOUT_API_CALL,
  ACTION_REMOVE_JOIN_ORGANISATION_API_CALL,
  ACTION_SEARCH_ORGANISATION_API_CALL,
  ACTION_UPDATE_PROPERTY_VISIBILE_API_CALL,
  ACTION_VIEW_SINGLE_PROPERTY_DETAILS_API_CALL,
  ACTION_VIEW_BANNER_DETAILS_API_CALL,
  ACTION_SAVED_FILTER_API_CALL,
  FORGOTPASSWORD_API_CALL,
  LOGIN_API_CALL,
  LOGIN_BY_TOKEN_API_CALL,
  MAKE_PROPERTY_FAVOURITE_API_CALL,
  MY_LISTING_API_CALL,
  MY_ORGANISATION_LISTING_API_CALL,
  OTP_API_CALL,
  REGISTER_API_CALL,
  RESETPASSWORD_API_CALL,
  actionRemoveSaveJoinOrganisation,
  actionUpdateHideProperty,
  ACTION_ADD_PROPERTY_API_CALL,
  ACTION_ADD_PROPERTY_API_CALL_V1,
  ACTION_ADD_BANNER_API_CALL,
  ACTION_UPDATE_BANNER_API_CALL,
  BANNER_CAMPAIGNS_API_CALL,
  BANNER_TEMPLATES_API_CALL,
  actionSaveLoginInfo,
  ACTION_GET_LANGUAGES_API_CALL,
  actionSaveGetLanguages,
  ACTION_CHANGE_LANGUAGE_API_CALL,
  actionSaveSelctedLanguage,
  ACTION_GET_ORGANISATION_DETAIL_API_CALL,
  actionSaveOrganisationDetail,
  actionSetLoader,
  ACTION_GET_HOME_BANNER_API_CALL,
  actionSaveGetHomeBanner,
  ACTION_HOME_STRAPI_API_CALL,
  ACTION_FOOTER_STRAPI_API_CALL,
  actionSaveHomeStrapi,
  actionSaveFooterStrapi,
  ACTION_GET_PROPERTY_TYPE_API_CALL,
  ACTION_GET_APPLICABLE_OPTIONS_API_CALL,
  ACTION_GET_CITIES_API_CALL,
  ACTION_GET_RENT_PREFIX_API_CALL,
  ACTION_GET_RENT_POSTFIX_API_CALL,
  ACTION_GET_SELL_PREFIX_API_CALL,
  ACTION_GET_SELL_POSTFIX_API_CALL,
  actionSaveGetPropertyType,
  actionSaveGetRentPrefix,
  actionSaveGetRentPostfix,
  actionSaveGetSellPrefix,
  actionSaveGetSellPostfix,
  actionSaveGetApplicableOptions,
  actionSaveGetCities,
  actionSaveHeaderStrapi,
  actionSaveCopyRightContentStrapi,
  actionSaveSocialIconsStrapi,
  ACTION_GET_SLUG_DATA_STRAPI_API_CALL,
  actionSaveSlugDataStrapi,
  ACTION_APPROVED_ORGANISATIONS_API_CALL,
  actionSaveApprovedOrganisations,
  ACTION_ADD_ORGANISATION_EMPLOYEE_API_CALL,
  actionSaveOrganisationEmployeeList,
  actionSaveEmployeeJobTitle,
  ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL,
  ACTION_REMOVE_ORGANISATION_EMPLOYEE_API_CALL,
  actionSaveRemoveOrganisationEmployee,
  ACTION_GET_SITE_SETTINGS_API_CALL,
  actionSaveGetSiteSettings,
  ACTION_CONTACT_US_INFO_API_CALL,
  ACTION_DELETE_MY_PROPERTY_API_CALL,
  ACTION_DELETE_PROPERTY_BANNER_API_CALL,
  actionSaveDeleteMyProperty,
  actionSaveDeletePropertyBanner,
  ACTION_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN_API_CALL,
  ACTION_CREATE_ORG_ACCOUNT_STEP2_API_CALL,
  actionSavePdfLink,
  ACTION_VERIFICATION_API_CALL,
  actionUserVerified,
  ACTION_MOLLIE_PAYMENT_API_CALL,
  actionSaveMolliePaymentUrl,
  ACTION_SEARCH_ORGANISATION_WITHOUT_LOGIN_API_CALL,
  actionSaveSearchOrganisationWithoutLogin,
  actionSaveIsPropertyAvailable,
  actionSaveSubscription,
  ACTION_GET_SUBSCIPTION_API_CALL,
  ACTION_CREATE_USER_SUBSCIPTION_API_CALL,
  actionSaveSubscriptionWithStatus,
  ACTION_SAVE_SUBSCIPTION_WITH_STATUS,
  ACTION_GET_SUBSCIPTION_WITH_STATUS_API_CALL,
  actionSaveSingleSubscriptionDetails,
  actionSaveCreateOrgAccountWithoutLogin,
  ACTION_CREATE_ORGANISATION_ACCOUNT_WITHOUT_LOGIN_API_CALL,
  ACTION_CREATE_ACCOUNT_WITH_SUBSCRIPTION_API_CALL,
  ACTION_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION_API_CALL,
  actionSaveSelfOrgansiationListingForSubscription,
  actionSaveGetCityList,
  actionSaveTranslationList,
  ACTION_GET_CITY_LIST_API_CALL,
  ACTION_GET_TRANSLATION_LIST_API_CALL,
  ACTION_CITY_INFO_API_CALL,
  actionSaveCityInfo,
  actionSaveAgencyBannerRecommendationsData,
  ACTION_GET_AGENCY_BANNER_RECOMMENDATIONS_API_CALL,
  ACTION_GET_AGENCY_BANNER_CLICK_COUNT_API_CALL,
  ACTION_GET_AUTO_SUGGEST_CITIES_API_CALL,
  actionSaveAutoSuggestCity,
  ACTION_GET_SUPPLIERS_API_CALL,
  actionSaveSuppliersData,
  MAKE_BANNER_STOP_API_CALL,
  ACTION_GET_BANNERS_API_CALL,
  actionSaveBannersData,
  ACTION_SAVE_ALL_PROPERTY_DATA_LISTING,
  actionSavePropertyData,
  ACTION_MY_INVOICES_API_CALL,
  ACTION_GET_INVOICE_API_CALL,
  actionSaveInvoicesData,
  actionSaveInvoiceData,
  actionMyListingApiCall,
  actionSaveDefaultPropertyBannerRegion,
  ACTION_GET_ALL_COUNTRIES_API_CALL,
  actionSaveGetAllCountries,
  actionSaveCountryImageInfo,
  ACTION_COUNTRY_IMAGE_GET_DATA,
  ACTION_GET_MAGAZINES_API_CALL,
  actionSaveMagazinesData,
  ACTION_GET_MAGAZINE_API_CALL,
  actionSaveMagazineData,
  ACTION_ADD_MAGAZINE_API_CALL,
  ACTION_UPDATE_MAGAZINE_API_CALL,
  ACTION_DELETE_MAGAZINE_API_CALL,
  ACTION_GET_MAGAZINE_AD_KINDS_API_CALL,
  ACTION_MODIFY_PLACEMENT_API_CALL,
  actionSaveMagazineAdKindsData,
  ACTION_GET_TOP_MENU_API_CALL,
  ACTION_GET_MAIN_MENU_API_CALL,
  actionSaveTopMenuData,
  actionSaveMainMenuData,
  ACTION_GET_DASHBOARD_SLIDER_DATA_API_CALL,
  actionSaveSilderDashboardData,
  ACTION_MODIFY_MAGAZINE_TEXT_API_CALL,
} from "../Actions";
import { toast } from "react-toastify";
import {
  FooterStrapiApiCall,
  GetSlugDataStrapiApiCall,
  HomeStrapiApiCall,
} from "../../API/APIStrapi";
import Loader from "../../components/Loader";

function* ErrorResponseHandler(navigate, err) {
  yield put(actionSetLoader(false));
  if (err.response.status === 401) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    localStorage.removeItem("agency_id");
    localStorage.removeItem("user_type");
    navigate("/login");
    toast.error(err.response.data.msg);
  } else {
    toast.error(err.response.data.msg);
  }
}

function* processLoginApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield LoginAPICall(body);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(actionSaveLoginToken(response.data.token));
      yield put(actionSaveLoginInfo(response.data.data));
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("user_id", response.data.data.id);
      localStorage.setItem("email", data.payload.data.email);
      localStorage.setItem("user_type", response.data.user_type);
      localStorage.setItem("isMember", response.data.isMember);
      response.data.data?.user_agency_detail?.id &&
        localStorage.setItem(
          "agency_id",
          response.data.data?.user_agency_detail?.id
        );
      toast.success(response.data.msg);
      if (response.data.isMember) {
        navigate("/broker-dashboard");
      } else {
        navigate("/home");
      }
      const selectedLanguage = yield select(
        (state) => state.CommonReducer.selectedLanguage
      );
      let bdata = {
        langCode: selectedLanguage.language,
      };
      const changeLanguageResponse = yield ChangeLanguageApiCall(bdata);
      if (changeLanguageResponse.status === 200) {
        yield put(
          actionSaveSelctedLanguage(changeLanguageResponse.data.languageObj)
        );
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processLoginByTokenApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let url = data.payload.url;
  let object = data.payload.object;
  yield put(actionSetLoader(true));
  try {
    const response = yield LoginByTokenAPICall(body);
    console.log("before status");
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(actionSaveLoginToken(response.data.token));
      yield put(actionSaveLoginInfo(response.data.data));
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("user_id", response.data.data.id);
      localStorage.setItem("email", response.data.data.email);
      localStorage.setItem("user_type", response.data.data.user_type);
      response.data.data?.user_agency_detail?.id &&
        localStorage.setItem(
          "agency_id",
          response.data.data?.user_agency_detail?.id
        );
      toast.success(response.data.msg);
      let data2 = {
        page: 20,
        per_page: 10,
        minPrice: "",
        maxPrice: "",
        listingType: "",
        isPrivateListing: "",
      };
      let body2 = { data2, navigate };
      yield put(actionMyListingApiCall(body2));
      const propertyData = {
        property_id: object,
        source: "mail",
      };
      const response2 = yield ViewSinglePropertyDetailApiCall(propertyData);
      if (response2?.status === 200) {
        console.log("response 2", response2.data.data);
        const newRegion = {
          name: `${response2.data.data.address}, ${response2.data.data.postcode} ${response2.data.data.city}`,
          type: "ZIPCODE",
          id: response2.data.data.zipcode_id,
          radius: 15,
          lat: response2.data.data.zipcode_detail.latitude,
          lng: response2.data.data.zipcode_detail.longitude,
        };
        console.log("newRegion", newRegion);
        yield put(actionSaveDefaultPropertyBannerRegion([newRegion]));
      }

      const selectedLanguage = yield select(
        (state) => state.CommonReducer.selectedLanguage
      );
      let bdata = {
        langCode: selectedLanguage.language,
      };
      const changeLanguageResponse = yield ChangeLanguageApiCall(bdata);
      if (changeLanguageResponse.status === 200) {
        yield put(
          actionSaveSelctedLanguage(changeLanguageResponse.data.languageObj)
        );
      }
      navigate(url);
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processRegisterApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield RegisterAPICall(body);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.msg);
      navigate("/account-created");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processForgotPasswordApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield ForgotPasswordAPICall(body);
    if (response.status === 200) {
      yield put(actionSaveForgotEmail(data.payload.data.email));
      toast.success(response.data.msg);
      navigate("/otp");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processOtpApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield OtpAPICall(body);
    if (response.status === 200) {
      yield put(actionSaveOtp(body.otp));
      toast.success(response.data.msg);
      yield put(actionSaveForgotEmail(response.data.data.email));
      navigate("/reset-password");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processResetPasswordApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  try {
    const response = yield ResetPasswordApiCall(body);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actionSaveLoginToken(response.data.token));
      yield put(actionSaveLoginInfo(response.data.data));
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("user_id", response.data.data.id);
      localStorage.setItem("email", data.payload.data.email);
      navigate("/home");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processLatestProperyListingHomeApiCall(data) {
  let body = data.payload;
  for (let i in body) {
    if (
      !body[i] ||
      body[i] == "null" ||
      body[i] == "undefined" ||
      body[i] == [""] ||
      typeof body[i] === null
    ) {
      delete body[i];
    }
  }
  yield put(actionSetLoader(true));
  try {
    const response = yield LatestProperyListingHomeApiCall(body);
    if (response.status === 200) {
      if (data.payload.type === "home") {
        yield put(
          actionSaveLatestPropertyListingHome(response.data.data.propertyData)
        );
        yield put(actionSetLoader(false));
      } else {
        yield put(actionSaveMyPropertyListing(response.data.data.propertyData));
        yield put(actionSetLoader(false));
      }
    }
  } catch (err) {
    yield put(actionSetLoader(false));
    if (err.response.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
    } else {
      toast.error(err.response.data.msg);
    }
  }
}
function* processEditAccountInfoApiCall(data) {
  let file = data.payload.fileValue;
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield EditAccountInfoApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      try {
        let formData = new FormData();
        formData.append("file", file);
        if (file) {
          const Imageresponse = yield UpdateImageApiCall(formData);
          if (Imageresponse.status === 200) {
            toast.success(response.data.msg);
          }
        } else {
          toast.success(response.data.msg);
        }
      } catch (err) {
        yield call(ErrorResponseHandler, navigate, err);
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processLogoutApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield LogoutApiCall();
    if (response.status === 200) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
      localStorage.removeItem("user_type");
      toast.success(response.data.msg);
      navigate("/");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processMakePropertyFavApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield MakePropertyFavApiCall(data.payload.data);
    if (response.status === 200) {
      toast.success(response.data.msg);
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processMyListingApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;
  yield put(actionSetLoader(true));
  for (let i in body) {
    if (body[i] == "1" || body[i] == "0") {
      body[i] = Number(body[i]);
    }
    if (body[i] == "null" || body[i] == "undefined" || body[i].length === 0) {
      delete body[i];
    }
  }
  try {
    const response = yield MyListingApiCall(body);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(actionSaveMyPropertyListing(response.data.data.propertyData));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processMyOrganisationListingApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield MyOrganisationListingApiCall();
    if (response.status === 200) {
      yield put(actionSaveMyOrganisationListing(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processAddOrganisationApiCall(data) {
  let navigate = data.payload.navigate;
  let file = data.payload.file;
  let bannerFile = data.payload.bannerFile;
  yield put(actionSetLoader(true));
  try {
    if (data.payload.id) {
      try {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("agencyId", data.payload.id);
        formData.append("banner", bannerFile);
        formData.append("is_edit", true);
        formData.append("address", data.payload.data.address);
        formData.append("postcode", data.payload.data.postcode);
        formData.append("city", data.payload.data.city);
        formData.append("phone", data.payload.data.phone);
        formData.append("primary_contact", data.payload.data.primary_contact);
        formData.append("website", data.payload.data.website);
        if (data.payload.data.shortDescription) {
          formData.append(
            "short_description",
            data.payload.data.shortDescription
          );
        }
        if (data.payload.data.description) {
          formData.append("description", data.payload.data.description);
        }
        const Imageresponse = yield AddOrganisationLogoApiCall(formData);
        if (Imageresponse.status === 200) {
          yield put(actionSetLoader(false));
          toast.success("Organization added successfully");
          navigate("/added-an-organisation");
        }
      } catch (err) {
        yield call(ErrorResponseHandler, navigate, err);
      }
    } else {
      const response = yield AddOrganisationApiCall(data.payload.data);
      if (response.status === 200) {
        try {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("agencyId", response.data.data.agency.id);
          formData.append("banner", bannerFile);
          const Imageresponse = yield AddOrganisationLogoApiCall(formData);
          if (Imageresponse.status === 200) {
            yield put(actionSetLoader(false));
            toast.success("Organization added successfully");
            navigate("/added-an-organisation");
          }
        } catch (err) {
          yield call(ErrorResponseHandler, navigate, err);
        }
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processSavedFilterApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield SavedFilterApiCall();
    if (response.status === 200) {
      yield put(actionSavedFilterData(response.data.data.savedFilterData));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processAddSavedFilterApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield AddSavedFilterApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSavedFilterData(response.data.data.filter));
      toast.success("filter data updated");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processSearchOrganisationApiCall(data) {
  let navigate = data.payload.navigate;
  let body = data.payload.data;
  for (let i in body) {
    if (!body[i] || body[i] == "null" || body[i] == "undefined") {
      delete body[i];
    }
  }
  yield put(actionSetLoader(true));
  try {
    const response = yield SearchOrganisationApiCall(body);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(actionSaveSearchOrganisation(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processJoinOrganisationApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield JoinAnOrganisationApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.msg);
      navigate("/joined-an-organisation");
    }
  } catch (err) {
    yield put(actionSetLoader(false));
    if (err.response.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
      navigate("/login");
    } else {
      toast.error(err?.response?.data[0]?.msg);
    }
  }
}
function* processRemoveJoinOrganisationApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield RemoveJoinAnOrganisationApiCall(data.payload.data);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actionRemoveSaveJoinOrganisation(data.payload.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processFavouritePropertyListingApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield FavouritePropertyListingApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(actionSaveMyPropertyListing(response.data.data.propertyData));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processViewSinglePropertyDetailsApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    let token = localStorage.getItem("authToken");
    let response;
    let propertyData;
    if (token) {
      propertyData = {
        property_id: data.payload.data.property_id,
        userId: localStorage.getItem("user_id"),
        source: data.payload.data.source,
      };
      response = yield ViewSinglePropertyDetailsApiCall(propertyData);
    } else {
      propertyData = {
        property_id: data.payload.data.property_id,
        source: data.payload.data.source,
      };
      response = yield ViewSinglePropertyDetailApiCall(propertyData);
    }
    if (response?.status === 200) {
      yield put(actionSaveIsPropertyAvailable(true));
      yield put(actionSaveSinglePropertyDetails(response.data.data));
      yield put(actionSetLoader(false));
    }
  } catch (err) {
    yield put(actionSaveIsPropertyAvailable(false));
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processViewBannerDetailsApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    yield put(actionSetLoader(true));
    const response = yield ViewBannerDetailsApiCall(data.payload);
    if (response.status === 200) {
      yield put(actionSaveBannerDetails(response.data.data));
      yield put(actionSetLoader(false));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processUpdatePropertyVisibleApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield UpdatePropertyVisibleApiCall(data.payload);
    if (response.status === 200) {
      yield put(actionUpdateHideProperty(data.payload));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processAddPropertyApiCall(data) {
  const navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    if (
      data.payload.imageData.property_id &&
      data.payload.imageData.image_id.length > 0
    ) {
      yield RemoveImagesApiCall(data.payload.imageData);
    }
    if (
      data.payload.priceData.property_id &&
      data.payload.priceData.price_id.length > 0
    ) {
      yield RemovePropertyPriceApiCall(data.payload.priceData);
    }
    const response = yield AddPropertyApiCall(data.payload.formData);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.data.msg);
      navigate("/my-listing");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processAddPropertyApiCallV1(data) {
  const navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    if (
      data.payload.imageData.property_id &&
      data.payload.imageData.image_id.length > 0
    ) {
      yield RemoveImagesApiCall(data.payload.imageData);
    }
    if (
      data.payload.priceData.property_id &&
      data.payload.priceData.price_id.length > 0
    ) {
      yield RemovePropertyPriceApiCall(data.payload.priceData);
    }
    const response = yield AddPropertyApiCallV1(data.payload.formData);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.data.msg);
      navigate("/my-listing");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processAddBannerApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    const response = yield AddBannerApiCall(data.payload.formData);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.data.msg);
      navigate("/my-banners");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processUpdateBannerApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    const response = yield UpdateBannerApiCall(data.payload.formData);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.data.msg);
      navigate("/my-banners");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processBannerCampaignsApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield BannerCampaignsApiCall();
    if (response.status === 200) {
      yield put(actionSaveBannerCampaignsListing(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processBannerTemplatesApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield BannerTemplatesApiCall();
    if (response.status === 200) {
      yield put(actionSaveBannerTemplatesListing(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetLanguagesApiCall() {
  try {
    const response = yield GetLanguagesApiCall();
    if (response.status === 200) {
      yield put(actionSaveGetLanguages(response.data.data));
    }
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
    } else {
      toast.error(err.response.data.msg);
    }
    console.log(err);
  }
}
function* processChangeLanguageApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield ChangeLanguageApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSaveSelctedLanguage(response.data.languageObj));
    }
  } catch (err) {
    if (err.response.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
      navigate("/login");
    } else {
      toast.error(err.response.data.msg);
      navigate("/login");
    }
    console.log(err);
  }
}
function* processGetOrganisationDetailApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield GetOrganisationDetailApiCall(data.payload.data.id);
    yield put(actionSetLoader(false));
    if (response.status === 200) {
      if (data.payload.title === "viewOrg") {
        yield put(actionSaveOrganisationDetail(response.data.data));
      } else {
        yield put(actionSaveOrganisationEmployeeList(response.data.data));
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetBannerApiCall(body) {
  const navigate = body.payload.navigate;
  try {
    const response = yield GetBannerApiCall();
    if (response.status === 200) {
      console.log("response.data.datahomebanner", response.data.data);
      yield put(actionSaveGetHomeBanner(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processHomeStrapiApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield HomeStrapiApiCall(
      selectedLanguage?.language ? selectedLanguage?.language : "nl",
      data.payload.title
    );
    if (response.status === 200) {
      if (data.payload.title === "home-pages") {
        yield put(actionSaveHomeStrapi(response.data));
      } else if (data.payload.title === "copy-right") {
        yield put(actionSaveCopyRightContentStrapi(response.data));
      } else if (data.payload.title === "socials") {
        yield put(actionSaveSocialIconsStrapi(response.data));
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processFooterStrapiApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield FooterStrapiApiCall(
      selectedLanguage?.language ? selectedLanguage?.language : "nl",
      data.payload.title
    );
    if (response.status === 200) {
      if (data.payload.title === "footer") {
        yield put(actionSaveFooterStrapi(response.data));
      } else if (data.payload.title === "header") {
        yield put(actionSaveHeaderStrapi(response.data));
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetPropertyTypeApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetPropertyTypeApiCall(selectedLanguage?.language);
    if (response.status === 200) {
      yield put(actionSaveGetPropertyType(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetApplicableOptionsApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetApplicableOptionsApiCall(
      selectedLanguage?.language
    );
    if (response.status === 200) {
      yield put(actionSaveGetApplicableOptions(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetCitiesApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetCitiesApiCall(selectedLanguage?.language);
    if (response.status === 200) {
      yield put(actionSaveGetCities(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetAutoSuggestCitiesApiCall(body) {
  let navigate = body.payload.navigate;
  yield put(actionSaveAutoSuggestCity([]));

  try {
    const response = yield GetAutoSuggestCityApiCall(body.payload);
    if (response.status === 200) {
      yield put(actionSaveAutoSuggestCity(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetRentPrefixApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetRentPrefixApiCall(selectedLanguage?.language);
    if (response.status === 200) {
      yield put(actionSaveGetRentPrefix(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetRentPostfixApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetRentPostfixApiCall(selectedLanguage?.language);
    if (response.status === 200) {
      yield put(actionSaveGetRentPostfix(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetSellPrefixApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetSellPrefixApiCall(selectedLanguage?.language);
    if (response.status === 200) {
      yield put(actionSaveGetSellPrefix(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetSellPostfixApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetSellPostfixApiCall(selectedLanguage?.language);
    if (response.status === 200) {
      yield put(actionSaveGetSellPostfix(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetSlugDataStrapiApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield GetSlugDataStrapiApiCall(
      selectedLanguage?.language,
      data.payload.slug
    );
    yield put(actionSetLoader(false));
    if (response.status === 200) {
      yield put(actionSaveSlugDataStrapi(response.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetApprovedOrganisationsApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield GetApprovedOrganisationsApiCall();
    if (response.status === 200) {
      yield put(actionSaveApprovedOrganisations(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processAddOrganisationEmployeeApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield AddOrganisationEmployeeApiCall(
      data.payload.formData
    );
    if (response.status === 200) {
      toast.success(response.data.msg);
      navigate(`/view-employee/${data.payload.agency_id}`);
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetEmployeeJobTitleApiCall(data) {
  const selectedLanguage = yield select(
    (state) => state.CommonReducer.selectedLanguage
  );
  let navigate = data.payload.navigate;
  try {
    const response = yield GetEmployeeJobTitleApiCall(
      selectedLanguage?.language
    );
    if (response.status === 200) {
      yield put(actionSaveEmployeeJobTitle(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processRemoveOrganisationEmployeeApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield RemoveOrganisationEmployeeApiCall(data.payload.data);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actionSaveRemoveOrganisationEmployee(data.payload.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processGetSiteSettingsApiCall() {
  try {
    const response = yield GetSiteSettingsApiCall();
    if (response?.status === 200) {
      yield put(actionSaveGetSiteSettings(response?.data?.data));
    }
  } catch (err) {
    if (err?.response?.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
    } else {
      toast.error(err?.response?.data?.msg);
    }
    console.log(err);
  }
}
function* processGetAllCountriesApiCall() {
  try {
    yield put(actionSetLoader(true));
    const response = yield GetAllCountriesApiCall();
    if (response.status === 200) {
      console.log("response.data.data", response.data.data);
      yield put(actionSaveGetAllCountries(response.data.data));
      yield put(actionSetLoader(false));
    }
  } catch (err) {
    toast.error("Something went wrong");
    yield put(actionSetLoader(false));
    console.log(err);
  }
}
function* processContactUsInfoApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield ContactUsInfoApiCall(data.payload.data);
    if (response.status === 200) {
      toast.success(response.data.msg);
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processDeleteMyPropertyApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield DeleteMyPropertyApiCall(data.payload.data.id);
    if (response.status === 200) {
      toast.success(response.data.msg || "Deleted Successfully");
      yield put(actionSaveDeleteMyProperty(data.payload.data.id));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processCreateOrgAccountWithoutLoginApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    if (localStorage.getItem("orgId")) {
      const saveOrgDetailsWithoutLogin = yield select(
        (state) => state.CommonReducer.saveOrgDetailsWithoutLogin
      );
      let CreateSubscriptionData = {
        product_id: data.payload.data.productId,
        product_payment_option_id: data.payload.data.id,
        user_id: saveOrgDetailsWithoutLogin.created_by,
        agency_id: saveOrgDetailsWithoutLogin.id,
        discount_and_extra_id: data.payload.data.discountExtraId,
      };
      const SubscriptionResponse = yield CreateUserSubsciptionApiCall(
        CreateSubscriptionData
      );
      if ((SubscriptionResponse.status = 200)) {
        toast.success(SubscriptionResponse.data.msg);
        window.open(SubscriptionResponse.data.redirectUrl, "_self");
      }
    } else {
      const response = yield CreateOrgAccountWithoutLoginApiCall(
        data.payload.formData
      );
      if (response.status === 200) {
        toast.success(response.data.msg || "Organisation Created Successfully");
        // navigate(`/create-org-account-step2/${response.data.data.id}`);
        localStorage.setItem("orgId", response.data.data.id);
        yield put(actionSaveCreateOrgAccountWithoutLogin(response.data.data));

        let CreateSubscriptionData = {
          product_id: data.payload.data.productId,
          product_payment_option_id: data.payload.data.id,
          user_id: response.data.data.created_by,
          agency_id: response.data.data.id,
          discount_and_extra_id: data.payload.data.discountExtraId,
        };
        const SubscriptionResponse = yield CreateUserSubsciptionApiCall(
          CreateSubscriptionData
        );
        if ((SubscriptionResponse.status = 200)) {
          toast.success(SubscriptionResponse.data.msg);
          window.open(SubscriptionResponse.data.redirectUrl, "_self");
        }
      }
    }
  } catch (err) {
    if (err?.response?.data?.error?.msg) {
      toast.error(`${err.response.data.error.msg}.${err.response.data.msg}`);
    } else {
      toast.error(err.response.data.msg);
    }
  }
}
function* processDeletePropertyBannerApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield DeletePropertyBannerApiCall(data.payload.data.id);
    if (response.status === 200) {
      toast.success(response.data.msg || "Deleted Successfully");
      console.log("response.data.data", response.data.data);
      yield put(actionSaveBannerCampaignsListing(response.data.data));
    }
  } catch (err) {
    if (err?.response?.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      toast.error(err.response.data.msg);
      navigate("/login");
    } else {
      toast.error(err.response.data.msg);
    }
    console.log(err);
  }
}

function* processCreateOrgAccountStep2ApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield CreateOrgAccountStep2ApiCall(data.payload.formData);
    if (response.status === 200) {
      if (data.payload.status == "PDF_DOWNLOADED") {
        window.open(
          "https://woningmarkt.s3.amazonaws.com/strapi_assets/Tiara_overeenkomst_1_9b5fc2c691.pdf"
        );
        yield put(
          actionSavePdfLink(
            "https://woningmarkt.s3.amazonaws.com/strapi_assets/Tiara_overeenkomst_1_9b5fc2c691.pdf"
          )
        );
      }
      if (data.payload.status == "PDF_SEND") {
        toast.success(response.data.msg);
        navigate("/home");
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processVerificationApiCall(data) {
  let body = data.payload.data;
  let navigate = data.payload.navigate;
  let path = data.payload.path;
  yield put(actionSetLoader(true));
  try {
    const response = yield VerificationApiCall(body);
    if (response.status === 200) {
      yield put(actionUserVerified(true));
      yield put(actionSetLoader(false));
      yield put(actionSaveLoginToken(response.data.token));
      yield put(actionSaveLoginInfo(response.data.data));
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("user_id", response.data.data.id);
      localStorage.setItem("email", data.payload.data.email);
      toast.success(response.data.msg);
      if (path) {
        setTimeout(() => {
          navigate(path);
        }, 3000);
      }
      const selectedLanguage = yield select(
        (state) => state.CommonReducer.selectedLanguage
      );
      let bdata = {
        langCode: selectedLanguage.language,
      };
      const changeLanguageResponse = yield ChangeLanguageApiCall(bdata);
      if (changeLanguageResponse.status === 200) {
        yield put(
          actionSaveSelctedLanguage(changeLanguageResponse.data.languageObj)
        );
      }
    }
  } catch (err) {
    console.log(err);
    yield put(actionSetLoader(false));
    yield put(actionUserVerified(false));
    if (err.response.status == 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
      navigate("/login");
    } else {
      toast.error(err.response.data.msg);
    }
  }
}
function* processMolliePaymentApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield MolliePaymentApiCall(data.payload.data);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actionSaveMolliePaymentUrl(response.data.data.paymentUrl));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}
function* processSearchOrganisationWithoutLoginApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield SearchOrganisationWithoutLoginApiCall(
      data.payload.data
    );
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(actionSaveSearchOrganisationWithoutLogin(response.data.data));
      navigate(`/search-organisation?${data.payload.queryString}`);
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetSubcriptionApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield SubscriptionListApiCall(
      data.payload.data,
      data.payload.title
    );
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      if (data.payload.title == "periodicity") {
        yield put(actionSaveSubscription(response.data.data));
      } else {
        yield put(actionSaveSingleSubscriptionDetails(response.data.data));
      }
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processCreateUserSubscriptionApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield CreateUserSubsciptionApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.msg);
      window.open(response.data.redirectUrl, "_self");
    }
  } catch (err) {
    if (err.response.status === 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
      navigate("/login");
    } else {
      toast.error(`${err.response.data.error.msg}.${err.response.data.msg}`);
    }
  }
}
function* processGetSubcriptionWithStatusApiCall(data) {
  let navigate = data.payload.navigate;
  yield put(actionSetLoader(true));
  try {
    const response = yield GetSubcriptionWithStatusApiCall(data.payload.data);

    if (response.status == 200) {
      yield put(actionSetLoader(false));
      yield put(
        actionSaveSubscriptionWithStatus(
          response.data.subscription.subscription_period_detail
        )
      );
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processCreateAccountWithSubscriptionApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    if (localStorage.getItem("user_id")) {
      let CreateSubscriptionData = {
        product_id: data.payload.data.productId,
        product_payment_option_id: data.payload.data.id,
        user_id: localStorage.getItem("user_id"),
        discount_and_extra_id: data.payload.data.discountExtraId,
      };
      const SubscriptionResponse = yield CreateUserSubsciptionApiCall(
        CreateSubscriptionData
      );
      if ((SubscriptionResponse.status = 200)) {
        toast.success(SubscriptionResponse.data.msg);
        window.open(SubscriptionResponse.data.redirectUrl, "_self");
      }
    } else {
      const response = yield RegisterAPICall(data.payload.accountData);
      if (response.status === 200) {
        toast.success(response.data.msg);
        // navigate(`/create-org-account-step2/${response.data.data.id}`);
        localStorage.setItem("user_id", response.data.data.user_id);
        let CreateSubscriptionData = {
          product_id: data.payload.data.productId,
          product_payment_option_id: data.payload.data.id,
          user_id: response.data.data.user_id,
          discount_and_extra_id: data.payload.data.discountExtraId,
        };
        const SubscriptionResponse = yield CreateUserSubsciptionApiCall(
          CreateSubscriptionData
        );
        if ((SubscriptionResponse.status = 200)) {
          toast.success(SubscriptionResponse.data.msg);
          window.open(SubscriptionResponse.data.redirectUrl, "_self");
        }
      }
    }
  } catch (err) {
    if (err?.response?.data?.error?.msg) {
      toast.error(`${err.response.data.error.msg}.${err.response.data.msg}`);
    } else {
      toast.error(err.response.data.msg);
    }
  }
}

function* processSelfOrganisationListingForSubscriptionApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield SelfOrganisationListingForSubscriptionApiCall(
      data.payload.data
    );
    if (response.status == 200) {
      yield put(
        actionSaveSelfOrgansiationListingForSubscription(response.data.data)
      );
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetCityListApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield GetCityListApiCall();
    if (response.status == 200) {
      yield put(actionSaveGetCityList(response.data.data.cities));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetTranslationListApiCall(data) {
  try {
    const response = yield GetTranslationListApiCall(data.payload.name);
    if (response.status == 200) {
      yield put(actionSaveTranslationList(response.data));
    }
  } catch (err) {}
}

function* processCityInfoApiCall(data) {
  // yield put(actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield getCityInfoApiCall(data.payload.search);
    if (response.status === 200) {
      // yield put(actionSetLoader(false));
      yield put(
        actionSaveCityInfo(response.data ? response.data.slice(0, 20) : [])
      );
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

///country banner api below
function* processCountryBannerApiCall(data) {
  yield put(actionSetLoader(true));
  try {
    const response = yield getCountryBannerApiCall(data.payload.search);

    if (response?.status === 200) {
      console.log(response?.data, "dynamic image");

      // Filter and map the data for type="COUNTRY"
      const filteredData = response?.data?.filter(
        (item) => item.type === "COUNTRY"
      );

      // Save the filtered data to the Redux store
      yield put(actionSaveCountryImageInfo(filteredData));
      localStorage.setItem("country_id", JSON.stringify(filteredData[0]?.id));
      console.log(filteredData, "datafilteredd");
      yield put(actionSetLoader(false));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, err);
  }
}

function* processGetAgencyBannerRecommendationsApiCall(data) {
  yield put(actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield GetAgencyBannerRecommendationsApiCall(
      data.payload.data
    );
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(
        actionSaveAgencyBannerRecommendationsData(response.data.sizeWiseBanner)
      );
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetAgencyBannerClickCountApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    const response = yield GetAgencyBannerClickCountApiCall(data.payload.data);
    // if (response.status === 200) {
    //   yield put(actionSetLoader(false));
    // }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetSuppliersApiCall(data) {
  yield put(actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield GetSuppliersApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      yield put(actionSaveSuppliersData(response.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processCreateOrganisationAccountWithoutLoginApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield CreateOrgAccountWithoutLoginApiCall(
      data.payload.formData
    );
    if (response.status === 200) {
      toast.success(response.data.msg || "Organisation Created Successfully");
      navigate(`/create-org-account-step2/${response.data.data.id}`);
    }
  } catch (err) {
    yield put(actionSetLoader(false));
    if (err.response.status === 401) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("user_id");
      localStorage.removeItem("email");
      localStorage.removeItem("agency_id");
    } else {
      toast.error(err.response.data.msg);
    }
  }
}

function* processMakeBannerStopApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield MakeBannerStopApiCall(data.payload.data);
    if (response.status === 200) {
      toast.success(response.data.msg);
      yield put(actionSaveBannerCampaignsListing(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetBannersApiCall(data) {
  yield put(actionSetLoader(true));
  const navigate = data.payload.navigate;
  try {
    const response = yield GetBannerIdApiCall(data.payload.data);
    if (response.status === 200) {
      yield put(actionSaveBannersData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetAllPropertyData(data) {
  const navigate = data.payload.navigate;
  try {
    console.log("data", data);
    const response = yield GetPropertyAllDataApiCall(data.payload);
    console.log("response", response);
    if (response.status === 200) {
      yield put(
        actionSavePropertyData(
          response.data.data.propertyData?.totalpropertyData
        )
      );
      // console.log("test",response.data.data.propertyData)
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processMyInvoicesApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    console.log(data);
    const response = yield MyInvoicesDataApiCall(data.payload);
    if (response.status === 200) {
      console.log("response", response.data);
      yield put(actionSaveInvoicesData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetInvoiceApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    console.log(data);
    const response = yield GetInvoiceDataApiCall(data.payload);
    if (response.status === 200) {
      yield put(actionSaveInvoiceData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

//MAGAZINES
function* processDeleteMagazineApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    const response = yield DeleteMagazineApiCall(data.payload.formData);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.data.msg);
      navigate("/my-magazines");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processAddMagazineApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    const response = yield AddMagazineApiCall(data.payload.formData);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.data.msg);
      navigate("/my-magazines");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processUpdateMagazineApiCall(data) {
  const navigate = data.payload.navigate;
  try {
    const response = yield UpdateMagazineApiCall(data.payload.formData);
    if (response.status === 200) {
      yield put(actionSetLoader(false));
      toast.success(response.data.data.msg);
      navigate("/my-magazines");
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetMagazinesApiCall(data) {
  let navigate = data.payload.navigate;
  try {
    const response = yield GetMagazinesApiCall();
    if (response.status === 200) {
      yield put(actionSaveMagazinesData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetMagazineApiCall(data) {
  console.log("processGetMagazineApiCall", data);
  let navigate = data.payload.navigate;
  try {
    const response = yield GetMagazineApiCall(data.payload);
    if (response.status === 200) {
      yield put(actionSaveMagazineData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetMagazineAdKindsApiCall(data) {
  console.log("processGetMagazineAdKindsApiCall", data);
  let navigate = data.payload.navigate;
  try {
    const response = yield GetMagazineAdKindsApiCall(data.payload);
    console.log("response", response.data);
    if (response.status === 200) {
      yield put(actionSaveMagazineAdKindsData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processModifyPlacementApiCall(data) {
  console.log("processModifyPlacementApiCall", data);
  let navigate = data.payload.navigate;
  try {
    const response = yield ModifyPlacementApiCall(data.payload);
    console.log("response", response.data);
    if (response.status === 200) {
      yield put(actionSaveMagazineData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetTopMenuApiCall(data) {
  console.log("processModifyPlacementApiCall", data);
  let navigate = data.payload.navigate;
  try {
    const response = yield GetTopMenuApiCall(data.payload);
    console.log("response", response.data);
    if (response.status === 200) {
      yield put(actionSaveTopMenuData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetMainMenuApiCall(data) {
  console.log("processModifyPlacementApiCall", data);
  let navigate = data.payload.navigate;
  try {
    const response = yield GetMainMenuApicall(data.payload);
    console.log("response", response.data);
    if (response.status === 200) {
      yield put(actionSaveMainMenuData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processGetDashboardSliderApiCall(data) {
  console.log("processModifyPlacementApiCall", data);
  let navigate = data.payload.navigate;
  try {
    const response = yield GetDahboardSliderApiCall(data.payload);
    console.log("the dashboardSliderAPI is", response.data);
    if (response.status === 200) {
      yield put(actionSaveSilderDashboardData(response.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* processModifyMagazineTextApiCall(data) {
  console.log("processModifyMagazineTextApiCall", data);
  let navigate = data.payload.navigate;
  try {
    const response = yield ModifyMagazineTextApiCall(data.payload);
    console.log("response", response.data);
    if (response.status === 200) {
      yield put(actionSaveMagazineData(response.data.data));
    }
  } catch (err) {
    yield call(ErrorResponseHandler, navigate, err);
  }
}

function* CommonSaga() {
  yield takeEvery(LOGIN_API_CALL, processLoginApiCall);
  yield takeEvery(LOGIN_BY_TOKEN_API_CALL, processLoginByTokenApiCall);
  yield takeEvery(REGISTER_API_CALL, processRegisterApiCall);
  yield takeEvery(FORGOTPASSWORD_API_CALL, processForgotPasswordApiCall);
  yield takeEvery(OTP_API_CALL, processOtpApiCall);
  yield takeEvery(RESETPASSWORD_API_CALL, processResetPasswordApiCall);
  yield takeEvery(
    ACTION_LATEST_PROPERTY_LISTING_HOME,
    processLatestProperyListingHomeApiCall
  );
  yield takeEvery(
    ACTION_EDIT_ACCOUNT_INFO_API_CALL,
    processEditAccountInfoApiCall
  );
  yield takeEvery(ACTION_LOGOUT_API_CALL, processLogoutApiCall);
  yield takeEvery(MY_LISTING_API_CALL, processMyListingApiCall);
  yield takeEvery(
    MAKE_PROPERTY_FAVOURITE_API_CALL,
    processMakePropertyFavApiCall
  );
  yield takeEvery(
    MY_ORGANISATION_LISTING_API_CALL,
    processMyOrganisationListingApiCall
  );
  yield takeEvery(
    ACTION_ADD_ORGANISATION_API_CALL,
    processAddOrganisationApiCall
  );
  yield takeEvery(ACTION_SAVED_FILTER_API_CALL, processSavedFilterApiCall);
  yield takeEvery(
    ACTION_ADD_SAVED_FILTER_API_CALL,
    processAddSavedFilterApiCall
  );
  yield takeEvery(
    ACTION_SEARCH_ORGANISATION_API_CALL,
    processSearchOrganisationApiCall
  );
  yield takeEvery(
    ACTION_JOIN_ORGANISATION_API_CALL,
    processJoinOrganisationApiCall
  );
  yield takeEvery(
    ACTION_REMOVE_JOIN_ORGANISATION_API_CALL,
    processRemoveJoinOrganisationApiCall
  );
  yield takeEvery(
    ACTION_FAVOURITE_PROPERTY_LISTING_API_CALL,
    processFavouritePropertyListingApiCall
  );
  yield takeEvery(
    ACTION_VIEW_SINGLE_PROPERTY_DETAILS_API_CALL,
    processViewSinglePropertyDetailsApiCall
  );
  yield takeEvery(
    ACTION_VIEW_BANNER_DETAILS_API_CALL,
    processViewBannerDetailsApiCall
  );
  yield takeEvery(
    ACTION_UPDATE_PROPERTY_VISIBILE_API_CALL,
    processUpdatePropertyVisibleApiCall
  );
  yield takeEvery(ACTION_ADD_PROPERTY_API_CALL, processAddPropertyApiCall);
  yield takeEvery(ACTION_ADD_PROPERTY_API_CALL_V1, processAddPropertyApiCallV1);
  yield takeEvery(ACTION_ADD_BANNER_API_CALL, processAddBannerApiCall);
  yield takeEvery(ACTION_UPDATE_BANNER_API_CALL, processUpdateBannerApiCall);
  yield takeEvery(BANNER_CAMPAIGNS_API_CALL, processBannerCampaignsApiCall);
  yield takeEvery(BANNER_TEMPLATES_API_CALL, processBannerTemplatesApiCall);
  yield takeEvery(ACTION_GET_LANGUAGES_API_CALL, processGetLanguagesApiCall);
  yield takeEvery(
    ACTION_CHANGE_LANGUAGE_API_CALL,
    processChangeLanguageApiCall
  );
  yield takeEvery(
    ACTION_GET_ORGANISATION_DETAIL_API_CALL,
    processGetOrganisationDetailApiCall
  );
  yield takeEvery(ACTION_GET_HOME_BANNER_API_CALL, processGetBannerApiCall);
  yield takeEvery(ACTION_HOME_STRAPI_API_CALL, processHomeStrapiApiCall);
  yield takeEvery(ACTION_FOOTER_STRAPI_API_CALL, processFooterStrapiApiCall);
  yield takeEvery(
    ACTION_GET_PROPERTY_TYPE_API_CALL,
    processGetPropertyTypeApiCall
  );
  yield takeEvery(
    ACTION_GET_APPLICABLE_OPTIONS_API_CALL,
    processGetApplicableOptionsApiCall
  );
  yield takeEvery(ACTION_GET_CITIES_API_CALL, processGetCitiesApiCall);
  yield takeEvery(
    ACTION_GET_AUTO_SUGGEST_CITIES_API_CALL,
    processGetAutoSuggestCitiesApiCall
  );

  yield takeEvery(ACTION_GET_RENT_PREFIX_API_CALL, processGetRentPrefixApiCall);
  yield takeEvery(
    ACTION_GET_RENT_POSTFIX_API_CALL,
    processGetRentPostfixApiCall
  );
  yield takeEvery(ACTION_GET_SELL_PREFIX_API_CALL, processGetSellPrefixApiCall);
  yield takeEvery(
    ACTION_GET_SELL_POSTFIX_API_CALL,
    processGetSellPostfixApiCall
  );
  yield takeEvery(
    ACTION_GET_SLUG_DATA_STRAPI_API_CALL,
    processGetSlugDataStrapiApiCall
  );
  yield takeEvery(
    ACTION_APPROVED_ORGANISATIONS_API_CALL,
    processGetApprovedOrganisationsApiCall
  );
  yield takeEvery(
    ACTION_ADD_ORGANISATION_EMPLOYEE_API_CALL,
    processAddOrganisationEmployeeApiCall
  );
  yield takeEvery(
    ACTION_GET_EMPLOYEE_JOB_TITLE_API_CALL,
    processGetEmployeeJobTitleApiCall
  );
  yield takeEvery(
    ACTION_REMOVE_ORGANISATION_EMPLOYEE_API_CALL,
    processRemoveOrganisationEmployeeApiCall
  );
  yield takeEvery(
    ACTION_GET_SITE_SETTINGS_API_CALL,
    processGetSiteSettingsApiCall
  );
  yield takeEvery(
    ACTION_GET_ALL_COUNTRIES_API_CALL,
    processGetAllCountriesApiCall
  );
  yield takeEvery(ACTION_CONTACT_US_INFO_API_CALL, processContactUsInfoApiCall);
  yield takeEvery(
    ACTION_DELETE_MY_PROPERTY_API_CALL,
    processDeleteMyPropertyApiCall
  );
  yield takeEvery(
    ACTION_DELETE_PROPERTY_BANNER_API_CALL,
    processDeletePropertyBannerApiCall
  );
  yield takeEvery(
    ACTION_CREATE_ORG_ACCOUNT_WITHOUT_LOGIN_API_CALL,
    processCreateOrgAccountWithoutLoginApiCall
  );
  yield takeEvery(
    ACTION_CREATE_ORG_ACCOUNT_STEP2_API_CALL,
    processCreateOrgAccountStep2ApiCall
  );
  yield takeEvery(ACTION_VERIFICATION_API_CALL, processVerificationApiCall);
  yield takeEvery(ACTION_MOLLIE_PAYMENT_API_CALL, processMolliePaymentApiCall);
  yield takeEvery(
    ACTION_SEARCH_ORGANISATION_WITHOUT_LOGIN_API_CALL,
    processSearchOrganisationWithoutLoginApiCall
  );
  yield takeEvery(
    ACTION_GET_SUBSCIPTION_API_CALL,
    processGetSubcriptionApiCall
  );
  yield takeEvery(
    ACTION_CREATE_USER_SUBSCIPTION_API_CALL,
    processCreateUserSubscriptionApiCall
  );
  yield takeEvery(
    ACTION_GET_SUBSCIPTION_WITH_STATUS_API_CALL,
    processGetSubcriptionWithStatusApiCall
  );
  yield takeEvery(
    ACTION_CREATE_ORGANISATION_ACCOUNT_WITHOUT_LOGIN_API_CALL,
    processCreateOrganisationAccountWithoutLoginApiCall
  );
  yield takeEvery(
    ACTION_CREATE_ACCOUNT_WITH_SUBSCRIPTION_API_CALL,
    processCreateAccountWithSubscriptionApiCall
  );
  yield takeEvery(
    ACTION_SELF_ORGANISATION_LISTING_FOR_SUBSCRIPTION_API_CALL,
    processSelfOrganisationListingForSubscriptionApiCall
  );
  yield takeEvery(ACTION_GET_CITY_LIST_API_CALL, processGetCityListApiCall);
  yield takeEvery(
    ACTION_GET_TRANSLATION_LIST_API_CALL,
    processGetTranslationListApiCall
  );

  yield takeEvery(ACTION_CITY_INFO_API_CALL, processCityInfoApiCall);
  yield takeEvery(ACTION_COUNTRY_IMAGE_GET_DATA, processCountryBannerApiCall);
  yield takeEvery(
    ACTION_GET_AGENCY_BANNER_RECOMMENDATIONS_API_CALL,
    processGetAgencyBannerRecommendationsApiCall
  );
  yield takeEvery(
    ACTION_GET_AGENCY_BANNER_CLICK_COUNT_API_CALL,
    processGetAgencyBannerClickCountApiCall
  );
  yield takeEvery(ACTION_GET_SUPPLIERS_API_CALL, processGetSuppliersApiCall);
  yield takeEvery(MAKE_BANNER_STOP_API_CALL, processMakeBannerStopApiCall);
  yield takeEvery(ACTION_GET_BANNERS_API_CALL, processGetBannersApiCall);
  yield takeEvery(
    ACTION_SAVE_ALL_PROPERTY_DATA_LISTING,
    processGetAllPropertyData
  );
  yield takeEvery(ACTION_MY_INVOICES_API_CALL, processMyInvoicesApiCall);
  yield takeEvery(ACTION_GET_INVOICE_API_CALL, processGetInvoiceApiCall);

  //MAGAZINES
  yield takeEvery(
    ACTION_DELETE_MAGAZINE_API_CALL,
    processDeleteMagazineApiCall
  );
  yield takeEvery(ACTION_ADD_MAGAZINE_API_CALL, processAddMagazineApiCall);
  yield takeEvery(
    ACTION_UPDATE_MAGAZINE_API_CALL,
    processUpdateMagazineApiCall
  );
  yield takeEvery(ACTION_GET_MAGAZINE_API_CALL, processGetMagazineApiCall);
  yield takeEvery(ACTION_GET_MAGAZINES_API_CALL, processGetMagazinesApiCall);
  yield takeEvery(
    ACTION_GET_MAGAZINE_AD_KINDS_API_CALL,
    processGetMagazineAdKindsApiCall
  );
  yield takeEvery(
    ACTION_MODIFY_PLACEMENT_API_CALL,
    processModifyPlacementApiCall
  );

  //broker
  yield takeEvery(ACTION_GET_TOP_MENU_API_CALL, processGetTopMenuApiCall);
  yield takeEvery(ACTION_GET_MAIN_MENU_API_CALL, processGetMainMenuApiCall);

  yield takeEvery(
    ACTION_GET_DASHBOARD_SLIDER_DATA_API_CALL,
    processGetDashboardSliderApiCall
  );

  yield takeEvery(
    ACTION_MODIFY_MAGAZINE_TEXT_API_CALL,
    processModifyMagazineTextApiCall
  );
}
export default CommonSaga;
